/* global window document */
import './polyfills'

import * as React from 'react'
import ReactDOM from 'react-dom'
import { loadableReady } from '@loadable/component'
import { Provider } from 'react-redux'
import { Router } from 'react-router'
import { createBrowserHistory } from 'history'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import * as amplitude from 'amplitude-js'

import Routes from './routes/client'
import configureStore from './stores/stores'

import { initAnalytics } from './libs/analytics'
import getGlobals from './helpers/getGlobals'
import isServer from './helpers/is-server'

moment.updateLocale('nb', require('moment/locale/nb'))

const initialState = window.INITIAL_STATE
const store = configureStore(initialState)
// store.runSaga(rootSagas)

const { currentUser } = store.getState()
const uniqueId =
  currentUser !== null && currentUser.data && {}.hasOwnProperty.call(currentUser.data, 'uniqueId')
    ? currentUser.data.uniqueId
    : ''
const userId =
  currentUser !== null && currentUser.data && {}.hasOwnProperty.call(currentUser.data, 'id')
    ? currentUser.data.id
    : ''

if (!isServer() && getGlobals().IS_PRODUCTION) {
  if (typeof window !== 'undefined') {
    amplitude.init(getGlobals().AMPLITUDE_API_KEY)
    window.amplitude = amplitude // To help us track amplitude data through GTM
  }
  initAnalytics(uniqueId, userId)

  Sentry.init({
    dsn: getGlobals().SENTRY_URL,
  })
}

const browserHistory = createBrowserHistory({})

const render = (Root) => {
  loadableReady(() => {
    ReactDOM.hydrate(
      <Provider store={store}>
        <Router history={browserHistory}>
          <Root />
        </Router>
      </Provider>,
      document.getElementById('root'),
    )
  })
}

if (module.hot) {
  module.hot.accept('./routes/client', () => {
    const NewApp = require('./routes/client').default
    render(NewApp)
  })
}

window.main = () => {
  render(Routes)
}
