import { createSelector } from 'reselect'

import { gigRequestType } from '../../models/GigRequest'

import * as types from './types'

const initialState = {
  data: {},
  list: [],
  hasFetched: false,
  isFetching: false,
  gigRequestUnreadCount: 0,
  requestCount: 0,
  gigRequest: {
    gigRequestId: '',
    hasFetched: false,
    isFetching: false,
    form: {
      data: {
        eventType: {
          eventType: gigRequestType.PRIVATE,
          sub: '',
        },
        audienceCount: 0,
        venue: '',
        city: '',
        startTime: '',
        endTime: '',
        notes: '',
        included: [],
      },
    },
  },
  gigRequestForArtist: {
    gigRequestId: '',
    artistId: '',
    hasFetched: false,
    isFetching: false,
  },
  quote: {
    isSending: false,
    hasSent: false,
    error: '',
  },
}

const gigRequestEventplannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_UNREAD_COUNT_SUCCESS: {
      return {
        ...state,
        gigRequestUnreadCount: action.payload.count || 0,
      }
    }
    case types.RESET_UNREAD_COUNT: {
      if (state.data[action.gigRequestId]) {
        return {
          ...state,
          data: {
            ...state.data,
            [action.gigRequestId]: {
              ...state.data[action.gigRequestId],
              artists: state.data[action.gigRequestId].artists.map(artist => ({
                ...artist,
                request: {
                  ...artist.request,
                  unread: artist.artist === action.artistId ? 0 : artist.request.unread, // this is the magic
                },
              })),
            },
          },
        }
      }
      return state
    }

    case types.FETCH_QUOTE_PENDING: {
      return {
        ...state,
        quote: {
          ...state.quote,
          isSending: true,
          hasSent: false,
          error: '',
        },
      }
    }
    case types.FETCH_QUOTE_SUCCESS: {
      return {
        ...state,
        quote: {
          ...state.quote,
          data: action.payload.data,
          isSending: false,
          hasSent: true,
          error: '',
        },
      }
    }
    case types.FETCH_QUOTE_ERROR: {
      return {
        ...state,
        quote: {
          ...state.quote,
          isSending: false,
          hasSent: false,
          error: action.error,
        },
      }
    }

    case types.SEND_MESSAGE: {
      if (state.data[action.gigRequestId]) {
        const newData = { ...state.data[action.gigRequestId] }
        if (!newData.artist || !newData.artist.timeline) {
          newData.artist = {
            ...newData.artist,
            timeline: [],
          }
        }
        newData.artist = {
          ...newData.artist,
          timeline: [
            {
              fromArtist: false,
              type: 'MESSAGE',
              time: action.date,
              data: {
                message: action.message,
              },
            },
            ...newData.artist.timeline,
          ],
        }
        return {
          ...state,
          data: {
            ...state.data,
            [action.gigRequestId]: newData,
          },
        }
      }
      return state
    }
    case types.FETCH_MY_EVENTPLANNER_REQUESTS_PENDING: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case types.FETCH_MY_EVENTPLANNER_REQUESTS_SUCCESS: {
      const newData = {}
      const newList = []
      if (action.payload.data && action.payload.data.requests) {
        action.payload.data.requests.forEach(gigRequest => {
          newData[gigRequest.id] = {
            ...state.data[gigRequest.id],
            ...gigRequest,
            artists: gigRequest.artists.map(element => ({
              ...element,
              artist: element.artist.id,
            })),
          }
          newList.push(gigRequest.id)
        })
      }

      return {
        ...state,
        data: {
          ...state.data,
          ...newData,
        },
        list: newList,
        isFetching: false,
        hasFetched: true,
      }
    }
    case types.FETCH_MY_EVENTPLANNER_REQUESTS_COUNT_SUCCESS: {
      return {
        ...state,
        requestCount: action.payload.requestCount,
      }
    }
    case types.FETCH_ONE_EVENTPLANNER_REQUEST_PENDING: {
      return {
        ...state,
        gigRequest: {
          ...state.gigRequest,
          gigRequestId: action.payload.gigRequestId,
          isFetching: true,
          form: {
            ...initialState.gigRequest.form,
          },
        },
      }
    }

    case types.FETCH_ONE_EVENTPLANNER_REQUEST_ERROR: {
      return {
        ...state,
        gigRequest: {
          ...state.gigRequest,
          isFetching: false,
          hasFetched: true,
        },
      }
    }

    case types.FETCH_ONE_EVENTPLANNER_REQUEST_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.gigRequestId]: {
            ...state.data[action.payload.gigRequestId],
            ...action.payload.data,
            artists: action.payload.data.artists.map(element => ({
              ...element,
              artist: element.artist.id,
            })),
          },
        },
        gigRequest: {
          ...state.gigRequest,
          gigRequestId: action.payload.gigRequestId,
          isFetching: false,
          hasFetched: true,
          form: {
            ...initialState.gigRequest.form,
            data: {
              eventType: action.payload.data.eventType,
              audienceCount: action.payload.data.audienceCount,
              venue: action.payload.data.venue,
              city: action.payload.data.city,
              startTime: action.payload.data.startTime,
              endTime: action.payload.data.endTime,
              notes: action.payload.data.notes,
              included: action.payload.data.included,
            },
          },
        },
      }
    }
    case types.FETCH_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST_PENDING: {
      return {
        ...state,
        gigRequestForArtist: {
          ...state.gigRequestForArtist,
          gigRequestId: action.payload.gigRequestId,
          artistId: action.payload.artistId,
          isFetching: true,
        },
      }
    }

    case types.FETCH_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST_ERROR: {
      return {
        ...state,
        gigRequestForArtist: {
          ...state.gigRequestForArtist,
          isFetching: false,
          hasFetched: true,
        },
      }
    }
    case types.RELOAD_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST_PENDING: {
      return {
        ...state,
        gigRequestForArtist: {
          ...state.gigRequestForArtist,
          gigRequestId: action.payload.gigRequestId,
          artistId: action.payload.artistId,
        },
      }
    }
    case types.FETCH_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST_SUCCESS: {
      let updatedArtists = []

      // This is because we need to update the request object on the artists list in gigRequest.
      if (
        state.data[action.payload.gigRequestId] &&
        state.data[action.payload.gigRequestId] &&
        state.data[action.payload.gigRequestId].artists &&
        state.data[action.payload.gigRequestId].artists.length > 0
      ) {
        updatedArtists = [...state.data[action.payload.gigRequestId].artists]
        const idx = updatedArtists.findIndex(artist => artist.artist === action.payload.artistId)
        if (idx !== -1) {
          updatedArtists[idx] = {
            ...action.payload.data.artist,
            artist: action.payload.data.artist.artist.id,
          }
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.gigRequestId]: {
            ...state.data[action.payload.gigRequestId],
            ...action.payload.data,
            artist: {
              ...action.payload.data.artist,
              artist: action.payload.data.artist.artist.id,
            },
            artists: updatedArtists, // this is okey, because get one does not contain artists.
          },
        },
        gigRequestForArtist: {
          ...state.gigRequest,
          gigRequestId: action.payload.gigRequestId,
          artistId: action.payload.artistId,
          isFetching: false,
          hasFetched: true,
        },
      }
    }
    default: {
      return state
    }
  }
}

export default gigRequestEventplannerReducer

const getGigRequestState = state => state.gigRequestEventplanner
const getArtistDataState = state => state.artist.data

export const isMyEventplannerRequestsLoading = state => getGigRequestState(state).isFetching
export const hasMyEventplannerRequestsLoaded = state => getGigRequestState(state).hasFetched
export const getMyEventplannerRequests = createSelector(
  [getGigRequestState, getArtistDataState],
  (gigArtistState, artistDataState) => {
    if (gigArtistState.list.length > 0) {
      return gigArtistState.list.map(id => ({
        ...gigArtistState.data[id],
        artists: gigArtistState.data[id].artists.map(element => ({
          ...element,
          artist: artistDataState[element.artist],
        })),
      }))
    }
    return []
  },
)

export const getOneEventplannerRequestIsLoading = state =>
  getGigRequestState(state).gigRequest.isFetching
export const getOneEventplannerRequestHasLoaded = state =>
  getGigRequestState(state).gigRequest.hasFetched
export const getOneEventplannerRequest = createSelector(
  [getGigRequestState, getArtistDataState],
  (gigRequestState, artistDataState) => {
    if (gigRequestState.data[gigRequestState.gigRequest.gigRequestId]) {
      return {
        ...gigRequestState.data[gigRequestState.gigRequest.gigRequestId],
        artists: gigRequestState.data[gigRequestState.gigRequest.gigRequestId].artists.map(
          element => ({
            ...element,
            artist: artistDataState[element.artist],
          }),
        ),
      }
    }
    return {}
  },
)

export const isOneEventplannerRequestForArtistLoading = state =>
  getGigRequestState(state).gigRequestForArtist.isFetching // eslint-disable-line
export const hasOneEventplannerRequestForArtistLoaded = state =>
  getGigRequestState(state).gigRequestForArtist.hasFetched // eslint-disable-line
export const getOneEventplannerRequestForArtist = createSelector(
  [getGigRequestState, getArtistDataState],
  (gigRequestState, artistDataState) => {
    if (gigRequestState.data[gigRequestState.gigRequestForArtist.gigRequestId]) {
      return {
        ...gigRequestState.data[gigRequestState.gigRequestForArtist.gigRequestId],
        artist: {
          ...gigRequestState.data[gigRequestState.gigRequestForArtist.gigRequestId].artist,
          artist: artistDataState[gigRequestState.gigRequestForArtist.artistId],
        },
      }
    }
    return {}
  },
)
export const getRequestCount = state => getGigRequestState(state).requestCount

export const getRequestFormData = state => getGigRequestState(state).gigRequest.form.data

export const getQuoteSelector = state => getGigRequestState(state).quote.data
export const getQuoteIsSendingSelector = state => getGigRequestState(state).quote.isSending
export const getQuoteHasSentSelector = state => getGigRequestState(state).quote.hasSent

export const getUnreadCount = state => getGigRequestState(state).gigRequestUnreadCount
