import { createSelector } from 'reselect'

import {
  FETCH_ME_PENDING,
  FETCH_ME_FULFILLED,
  FETCH_ME_REJECTED,
  UPDATE_ME_PENDING,
  UPDATE_ME_FULFILLED,
  UPDATE_ME_REJECTED,
} from './CurrentUserActions'

const initialState = {
  data: null,
  hasFetched: false,
  isFetching: false,
  hasUpdated: false,
  isUpdating: false,
  errorCode: null,
  errorMessage: null,
}

const currentUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ME_PENDING: {
      return {
        ...state,
        isFetching: true,
        isUpdating: false,
        errorCode: null,
        errorMessage: null,
      }
    }
    case FETCH_ME_FULFILLED: {
      if (!action.payload) return state
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
        hasFetched: true,
        isFetching: false,
      }
    }
    case FETCH_ME_REJECTED: {
      return {
        ...state,
        data: null,
        hasFetched: true,
        isFetching: false,
      }
    }
    case UPDATE_ME_PENDING: {
      return {
        ...state,
        isUpdating: true,
      }
    }
    case UPDATE_ME_FULFILLED: {
      return {
        ...state,
        hasUpdated: true,
        isUpdating: false,
        errorCode: null,
        errorMessage: null,
      }
    }
    case UPDATE_ME_REJECTED: {
      return {
        ...state,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        hasUpdated: false,
        isUpdating: false,
      }
    }
    default: {
      return state
    }
  }
}

export default currentUserReducer

const currentUserState = state => state.currentUser

export const getCurrentUser = createSelector(
  currentUserState,
  state => state.data || {},
)

export const getIsFetching = createSelector(
  currentUserState,
  state => state.isFetching,
)

export const getHasFetched = createSelector(
  currentUserState,
  state => state.hasFetched,
)

export const getIsUpdating = createSelector(
  currentUserState,
  state => state.isUpdating,
)

export const getHasUpdated = createSelector(
  currentUserState,
  state => state.hasUpdated,
)

export const getErrorMessage = createSelector(
  currentUserState,
  state => state.errorMessage,
)
