/* eslint-disable  import/prefer-default-export */

import { getJSON } from '../../libs/data'
import * as types from './OptionsTypes'
import { getIsLoadedReducer } from './OptionsReducer'

export const initOptionsAction = req => dispatch => {
  dispatch({
    type: types.GET_OPTIONS_PENDING,
  })
  return getJSON('/api/v1/user/init', { req, ttl: 900 }).then(
    json => {
      dispatch({
        type: types.GET_OPTIONS_SUCCESS,
        data: json,
      })
    },
    error => {
      dispatch({
        type: types.GET_OPTIONS_REJECTED,
        errorCode: error.statusCode,
        errorMessage: error.response && error.response.message ? error.response.message : '',
      })
    },
  )
}

export const initOptionsIfNeededAction = () => (dispatch, getState) => {
  if (!getIsLoadedReducer(getState())) {
    dispatch(initOptionsAction())
  }
}
