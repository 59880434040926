import { css } from 'styled-components'

const RANGE = {
  mobile: [0, 600],
  tabletPortrait: [601, 900],
  tabletLandscape: [901, 1200],
  desktop: [1201, 1800],
  desktopBig: [1801, 3000],
}

const screen = 'screen'

export const mediaQueriesDefinitions = {
  landscape: `${screen} and (orientation: landscape)`,
  portrait: `${screen} and (orientation: portrait)`,
  // Horizontal mediaqueries
  mobile: `${screen} and (min-width:${RANGE.mobile[0]}px) and (max-width:${RANGE.mobile[1]}px)`,
  mobileDown: `${screen} and (max-width:${RANGE.mobile[1]}px)`,
  mobileUp: `${screen} and (min-width:${RANGE.mobile[0]}px)`,

  tabletPortrait: `${screen} and (min-width:${RANGE.tabletPortrait[0]}px) and (max-width:${
    RANGE.tabletPortrait[1]
  }px)`,
  tabletPortraitUp: `${screen} and (min-width:${RANGE.tabletPortrait[0]}px)`,
  tabletPortraitDown: `${screen} and (max-width:${RANGE.tabletPortrait[1]}px)`,

  tabletLandscape: `${screen} and (min-width:${RANGE.tabletLandscape[0]}px) and (max-width:${
    RANGE.tabletLandscape[1]
  }px)`,
  tabletLandscapeUp: `${screen} and (min-width:${RANGE.tabletLandscape[0]}px)`,
  tabletLandscapeDown: `${screen} and (max-width:${RANGE.tabletLandscape[1]}px)`,

  desktop: `${screen} and (min-width:${RANGE.desktop[0]}px) and (max-width:${RANGE.desktop[1]}px)`,
  desktopUp: `${screen} and (min-width:${RANGE.desktop[0]}px)`,
  desktopDown: `${screen} and (max-width:${RANGE.desktop[1]}px)`,

  desktopBig: `${screen} and (min-width:${RANGE.desktopBig[0]}px) and (max-width:${
    RANGE.desktopBig[1]
  }px)`,
  desktopBigUp: `${screen} and (min-width:${RANGE.desktopBig[0]}px)`,
  desktopBigDown: `${screen} and (max-width:${RANGE.desktopBig[1]}px)`,
}

// Iterate through the sizes and create a media template
const mediaQueries = Object.keys(mediaQueriesDefinitions).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media ${mediaQueriesDefinitions[label]} {
      ${css(...args)};
    }
  `
  return acc
}, {})

export default mediaQueries
