export const FETCH_ARTIST_PENDING = 'ARTIST/FETCH_ARTIST_PENDING'
export const FETCH_ARTIST_FULFILLED = 'ARTIST/FETCH_ARTIST_FULFILLED'
export const FETCH_ARTIST_REJECTED = 'ARTIST/FETCH_ARTIST_REJECTED'
export const RESET_SERVER_FETCHED = 'ARTIST/RESET_SERVER_FETCHED'

export const FETCH_USERARTISTS_PENDING = 'ARTIST/FETCH_USERARTISTS_PENDING'
export const FETCH_USERARTISTS_FULFILLED = 'ARTIST/FETCH_USERARTISTS_FULFILLED'
export const FETCH_USERARTISTS_REJECTED = 'ARTIST/FETCH_USERARTISTS_REJECTED'

export const FETCH_ARTIST_PAGEVIEW_STATS_PENDING = 'ARTIST/FETCH_PAGEVIEW_STATS_PENDING'
export const FETCH_ARTIST_PAGEVIEW_STATS_FULFILLED = 'ARTIST/FETCH_PAGEVIEW_STATS_FULFILLED'
export const FETCH_ARTIST_PAGEVIEW_STATS_REJECTED = 'ARTIST/FETCH_PAGEVIEW_STATS_REJECTED'

export const FETCH_FRONTPAGE_ARTISTS_PENDING = 'ARTIST/FETCH_FRONTPAGE_ARTISTS_PENDING'
export const FETCH_FRONTPAGE_ARTISTS_FULFILLED = 'ARTIST/FETCH_FRONTPAGE_ARTISTS_FULFILLED'
export const FETCH_FRONTPAGE_ARTISTS_REJECTED = 'ARTIST/FETCH_FRONTPAGE_ARTISTS_REJECTED'

export const FETCH_JOIN_ARTISTS_PENDING = 'ARTIST/FETCH_JOIN_ARTISTS_PENDING'
export const FETCH_JOIN_ARTISTS_FULFILLED = 'ARTIST/FETCH_JOIN_ARTISTS_FULFILLED'
export const FETCH_JOIN_ARTISTS_REJECTED = 'ARTIST/FETCH_JOIN_ARTISTS_REJECTED'

export const ADD_FAVORITE = 'ARTIST/ADD_FAVORITE'
export const ADD_FAVORITE_FULFILLED = 'ARTIST/ADD_FAVORITE_FULFILLED'
export const ADD_FAVORITE_ERROR = 'ARTIST/ADD_FAVORITE_ERROR'
export const REMOVE_FAVORITE = 'ARTIST/REMOVE_FAVORITE'
export const REMOVE_FAVORITE_FULFILLED = 'ARTIST/REMOVE_FAVORITE_FULFILLED'
export const REMOVE_FAVORITE_ERROR = 'ARTIST/REMOVE_FAVORITE_ERROR'
export const FETCH_FAVORITE_ARTISTS_PENDING = 'ARTIST/FETCH_FAVORITE_ARTISTS_PENDING'
export const FETCH_FAVORITE_ARTISTS_FULFILLED = 'ARTIST/FETCH_FAVORITE_ARTISTS_FULFILLED'
export const FETCH_FAVORITE_ARTISTS_REJECTED = 'ARTIST/FETCH_FAVORITE_ARTISTS_REJECTED'

export const FETCH_MATCHING_ARTISTS_PENDING = 'ARTIST/FETCH_MATCHING_ARTISTS_PENDING'
export const FETCH_MATCHING_ARTISTS_FULFILLED = 'ARTIST/FETCH_MATCHING_ARTISTS_FULFILLED'
export const FETCH_MATCHING_ARTISTS_REJECTED = 'ARTIST/FETCH_MATCHING_ARTISTS_REJECTED'

export const SET_SEARCH_DATA = 'ARTIST/SET_SEARCH_DATA'
export const RESET_SEARCH_DATA = 'ARTIST/RESET_SEARCH_DATA'

export const FETCH_SEARCH_PENDING = 'ARTIST/FETCH_SEARCH_PENDING'
export const FETCH_SEARCH_FULFILLED = 'ARTIST/FETCH_SEARCH_FULFILLED'
export const FETCH_SEARCH_REJECTED = 'ARTIST/FETCH_SEARCH_REJECTED'
