import { call, put, takeLatest, all } from 'redux-saga/effects'

import { getJSON } from '../../libs/data'
import * as types from './types'
import * as actions from './actions'

function* getMyEventplannerRequests() {
  const url = '/api/v1/gigRequest/myRequests'
  yield put(actions.fetchMyEventplannerRequestsPendingAction())
  try {
    const gigRequests = yield call(getJSON, url)
    yield put(actions.fetchMyEventplannerRequestsSuccessAction(gigRequests))
  } catch (error) {
    yield put(actions.fetchMyEventplannerRequestsErrorAction(error))
  }
}

function* getOneRequest(action) {
  const url = `/api/v1/gigRequest/getOneRequest?gigRequestId=${action.gigRequestId}`
  yield put(actions.fetchOneEventplannerRequestPendingAction(action.gigRequestId))
  try {
    const gigRequest = yield call(getJSON, url)
    yield put(actions.fetchOneEventplannerRequestSuccessAction(action.gigRequestId, gigRequest))
  } catch (error) {
    yield put(actions.fetchOneEventplannerRequestErrorAction(error))
  }
}

function* getOneQuote(action) {
  const url = `/api/v1/gigRequest/getOneQuote?quoteId=${action.quoteId}`

  yield put(actions.fetchQuotePendingAction())
  try {
    const quote = yield call(getJSON, url)
    yield put(actions.fetchQuoteSuccessAction(action.quoteId, quote))
  } catch (error) {
    yield put(actions.fetchQuoteErrorAction(error))
  }
}

function* getOneRequestForArtist(action) {
  const url = `/api/v1/gigRequest/getOneRequestForArtist?gigRequestId=${
    action.gigRequestId
  }&artistId=${action.artistId}`
  yield put(
    actions.fetchOneEventplannerRequestForArtistPendingAction(action.gigRequestId, action.artistId),
  )
  try {
    const gigRequest = yield call(getJSON, url)
    yield put(
      actions.fetchOneEventplannerRequestForArtistSuccessAction(
        action.gigRequestId,
        action.artistId,
        gigRequest,
      ),
    )
  } catch (error) {
    yield put(actions.fetchOneEventplannerRequestForArtistErrorAction(error))
  }
}

function* reloadOneRequestForArtist(action) {
  const url = `/api/v1/gigRequest/getOneRequestForArtist?gigRequestId=${
    action.gigRequestId
  }&artistId=${action.artistId}`
  yield put(
    actions.reloadOneEventplannerRequestForArtistPendingAction(
      action.gigRequestId,
      action.artistId,
    ),
  )
  try {
    const gigRequest = yield call(getJSON, url)
    yield put(
      actions.fetchOneEventplannerRequestForArtistSuccessAction(
        action.gigRequestId,
        action.artistId,
        gigRequest,
      ),
    )
  } catch (error) {
    yield put(actions.fetchOneEventplannerRequestForArtistErrorAction(error))
  }
}

function* updateUnreadCount() {
  try {
    const data = yield call(getJSON, '/api/v1/gigrequest/unreadCount')
    yield put({ type: types.UPDATE_UNREAD_COUNT_SUCCESS, payload: { count: data.count } })
  } catch (error) {
    // DO NOTHING
  }
}

export default function* gigRequestEventplannerSagas() {
  yield all([
    takeLatest(types.GET_QUOTE, getOneQuote),
    takeLatest(types.GET_MY_EVENTPLANNER_REQUESTS, getMyEventplannerRequests),
    takeLatest(types.GET_ONE_EVENTPLANNER_REQUEST, getOneRequest),
    takeLatest(types.GET_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST, getOneRequestForArtist),
    takeLatest(types.RELOAD_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST, reloadOneRequestForArtist),
    takeLatest(types.UPDATE_UNREAD_COUNT, updateUnreadCount),
  ])
}
