import { getJSON } from '../../libs/data'
import { hasCountiesLoadedSelector } from './LocationsReducer'

export const FETCH_LOCATIONS_PENDING = 'FETCH_LOCATIONS_PENDING'
export const FETCH_LOCATIONS_FULFILLED = 'FETCH_LOCATIONS_FULFILLED'
export const FETCH_LOCATIONS_REJECTED = 'FETCH_LOCATIONS_REJECTED'

export const FETCH_LOCATIONS_CHILDREN_PENDING = 'FETCH_LOCATIONS_CHILDREN_PENDING'
export const FETCH_LOCATIONS_CHILDREN_FULFILLED = 'FETCH_LOCATIONS_CHILDREN_FULFILLED'
export const FETCH_LOCATIONS_CHILDREN_REJECTED = 'FETCH_LOCATIONS_CHILDREN_REJECTED'

export const getCounties = (req = null) => dispatch => {
  dispatch({ type: FETCH_LOCATIONS_PENDING })
  return getJSON('/api/v1/location/getLocations', { req, ttl: 60 * 60 * 4 }).then(
    json => {
      dispatch({
        type: FETCH_LOCATIONS_FULFILLED,
        payload: {
          data: json,
          receivedAt: Date.now(),
        },
      })
    },
    error => {
      dispatch({ type: FETCH_LOCATIONS_REJECTED })
    },
  )
}

export const getCountiesIfNeededAction = () => (dispatch, getState) => {
  if (!hasCountiesLoadedSelector(getState())) {
    dispatch(getCounties())
  }
}

export const getChildrenLocations = parentId => dispatch => {
  dispatch({ type: FETCH_LOCATIONS_CHILDREN_PENDING })
  return getJSON(`/api/v1/location/getLocations?parent=${parentId}`, {
    req: {},
    ttl: 60 * 60 * 4,
  }).then(
    json => {
      dispatch({
        type: FETCH_LOCATIONS_CHILDREN_FULFILLED,
        payload: {
          data: json,
          parentId,
          receivedAt: Date.now(),
        },
      })
    },
    error => {
      dispatch({ type: FETCH_LOCATIONS_CHILDREN_REJECTED })
    },
  )
}

export const getLocationsIfNeeded = (req = null) => (dispatch, getState) => {
  const {
    locations: { counties, isFetching },
  } = getState()

  if ((counties && counties.length > 0) || isFetching) {
    return null
  }
  return dispatch(getCounties(req))
}
