import { getJSON, postJSON } from '../../libs/data'

export const FETCH_ME_PENDING = 'FETCH_ME_PENDING'
export const FETCH_ME_FULFILLED = 'FETCH_ME_FULFILLED'
export const FETCH_ME_REJECTED = 'FETCH_ME_REJECTED'

export const UPDATE_ME_PENDING = 'UPDATE_ME_PENDING'
export const UPDATE_ME_FULFILLED = 'UPDATE_ME_FULFILLED'
export const UPDATE_ME_REJECTED = 'UPDATE_ME_REJECTED'

export const fetchMe = req => dispatch => {
  dispatch({ type: FETCH_ME_PENDING })
  return getJSON('/api/v1/user/me', { req }).then(
    json => {
      dispatch({
        type: FETCH_ME_FULFILLED,
        payload: {
          data: json,
          receivedAt: Date.now(),
        },
      })
    },
    error => {
      dispatch({ type: FETCH_ME_REJECTED })
    },
  )
}

export const updateMe = user => dispatch => {
  dispatch({
    type: UPDATE_ME_PENDING,
  })
  const url = '/api/v1/user/update'
  postJSON(url, user).then(
    json => {
      dispatch({
        type: UPDATE_ME_FULFILLED,
        data: json,
      })
      dispatch(fetchMe())
    },
    error => {
      dispatch({
        type: UPDATE_ME_REJECTED,
        errorCode: error.statusCode,
        errorMessage: error.response && error.response.message ? error.response.message : '',
      })
    },
  )
}
