import * as React from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'

import { trackError, trackEvent } from '../../libs/analytics'
import { Container, Text, LogoWrapper, Spacer, FrontpageLink } from './style'
import GigplanetLogo from '../../ui/GigplanetLogo'
import Button from '../../ui/Button'
import GlobalStyle from '../global-style'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch = (error, errorInfo) => {
    this.setState({ error })
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
    trackError(errorInfo)
  }

  openIntercom = () => {
    trackEvent('error', 'open-intercom-from-error')
    if (window && window.Intercom) {
      window.Intercom('showNewMessage')
    }
  }

  render() {
    const { error } = this.state
    const { children } = this.props

    if (error) {
      return (
        <Container>
          <LogoWrapper>
            <GigplanetLogo />
          </LogoWrapper>
          <Text>
            Uff. Her har det skjedd noe feil. Sorry. Vi setter veldig pris på om du kan fortelle oss
            om hva som har skjedd. På denne måten kan vi gjøre Gigplanet enda bedre!
          </Text>
          <Button type="button" theme="primary" onClick={this.openIntercom}>
            Fortell oss om feilen
          </Button>
          <Spacer />
          <FrontpageLink href="/">Gå tilbake til forsiden</FrontpageLink>
          <GlobalStyle />
        </Container>
      )
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
}

export default ErrorBoundary
