export const RESET_FORMS = 'AUTH/RESET_FORMS'

export const UPDATE_REGISTER_FORM = 'AUTH/UPDATE_REGISTER_FORM'
export const UPDATE_LOGIN_FORM = 'AUTH/UPDATE_LOGIN_FORM'
export const UPDATE_FORGOT_PASSWORD_FORM = 'AUTH/UPDATE_FORGOT_PASSWORD_FORM'
export const UPDATE_NEW_PASSWORD_FORM = 'AUTH/UPDATE_NEW_PASSWORD_FORM'
export const UPDATE_VERIFY_EMAIL_FORM = 'AUTH/UPDATE_VERIFY_EMAIL_FORM'

export const CREATE_USER = 'AUTH/CREATE_USER'
export const CREATE_USER_PENDING = 'AUTH/CREATE_USER_PENDING'
export const CREATE_USER_ERROR = 'AUTH/CREATE_USER_ERROR'
export const CREATE_USER_SUCCESS = 'AUTH/CREATE_USER_SUCCESS'

export const LOGIN_USER = 'AUTH/LOGIN_USER'
export const LOGIN_USER_PENDING = 'AUTH/LOGIN_USER_PENDING'
export const LOGIN_USER_ERROR = 'AUTH/LOGIN_USER_ERROR'
export const LOGIN_USER_SUCCESS = 'AUTH/LOGIN_USER_SUCCESS'

export const FORGOT_PASSWORD = 'AUTH/FORGOT_PASSWORD'
export const FORGOT_PASSWORD_PENDING = 'AUTH/FORGOT_PASSWORD_PENDING'
export const FORGOT_PASSWORD_ERROR = 'AUTH/FORGOT_PASSWORD_ERROR'
export const FORGOT_PASSWORD_SUCCESS = 'AUTH/FORGOT_PASSWORD_SUCCESS'

export const NEW_PASSWORD = 'AUTH/NEW_PASSWORD'
export const NEW_PASSWORD_PENDING = 'AUTH/NEW_PASSWORD_PENDING'
export const NEW_PASSWORD_ERROR = 'AUTH/NEW_PASSWORD_ERROR'
export const NEW_PASSWORD_SUCCESS = 'AUTH/NEW_PASSWORD_SUCCESS'

export const VERIFY_EMAIL = 'AUTH/VERIFY_EMAIL'
export const VERIFY_EMAIL_PENDING = 'AUTH/VERIFY_EMAIL_PENDING'
export const VERIFY_EMAIL_ERROR = 'AUTH/VERIFY_EMAIL_ERROR'
export const VERIFY_EMAIL_SUCCESS = 'AUTH/VERIFY_EMAIL_SUCCESS'

export const GET_AUTH_DATA = 'AUTH/GET_AUTH_DATA'
