import { createSelector } from 'reselect'

import * as types from './types'

const initialState = {
  systemText: {},
  landingPage: {},
  siteSettings: {},
  article: {},
  articles: [],
}

const cmsContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SYSTEM_TEXT_FULFILLED: {
      return {
        ...state,
        systemText: {
          ...state.systemText,
          [action.payload.slug]: {
            content: {
              ...action.payload.json,
            },
            loaded: true,
          },
        },
      }
    }

    case types.FETCH_LANDING_PAGE_PENDING: {
      return {
        ...state,
        landingPage: {
          ...state.landingPage,
          [action.payload.slug]: {
            ...(state.landingPage[action.payload.slug]
              ? state.landingPage[action.payload.slug]
              : {}),
            loading: true,
          },
        },
      }
    }
    case types.FETCH_LANDING_PAGE_FULFILLED: {
      return {
        ...state,
        landingPage: {
          ...state.landingPage,
          [action.payload.slug]: {
            content: action.payload.json.landingPage
              ? { ...action.payload.json.landingPage }
              : null,
            loaded: true,
            loading: false,
          },
        },
        siteSettings: {
          ...action.payload.json.siteSettings,
        },
      }
    }
    case types.FETCH_LANDING_PAGE_REJECTED: {
      return {
        ...state,
        landingPage: {
          ...state.landingPage,
          [action.payload.slug]: {
            ...(state.landingPage[action.payload.slug]
              ? state.landingPage[action.payload.slug]
              : {}),
            loading: false,
          },
        },
      }
    }
    case types.FETCH_ONE_ARTICLE_PENDING: {
      return {
        ...state,
        article: {
          ...state.article,
          [action.payload.slug]: {
            ...(state.article[action.payload.slug] ? state.article[action.payload.slug] : {}),
            loading: true,
          },
        },
      }
    }
    case types.FETCH_ONE_ARTICLE_FULFILLED: {
      return {
        ...state,
        article: {
          ...state.article,
          [action.payload.slug]: {
            content: action.payload.json.article ? { ...action.payload.json.article } : null,
            loaded: true,
            loading: false,
          },
        },
        siteSettings: {
          ...action.payload.json.siteSettings,
        },
        landingPage: {
          ...state.landingPage,
          [action.payload.landingPageSlug]: {
            content: action.payload.json.landingPage
              ? { ...action.payload.json.landingPage }
              : null,
            loaded: true,
            loading: false,
          },
        },
      }
    }
    case types.FETCH_ONE_ARTICLE_REJECTED: {
      return {
        ...state,
        article: {
          ...state.article,
          [action.payload.slug]: {
            ...(state.article[action.payload.slug] ? state.article[action.payload.slug] : {}),
            loading: false,
          },
        },
      }
    }

    default:
      return state
  }
}

export default cmsContentReducer

const getCMSContentState = (state) => state.cmsContent
const getArticleState = (state) => getCMSContentState(state).article
const getSystemTextState = (state) => getCMSContentState(state).systemText
const getLandingPageState = (state) => getCMSContentState(state).landingPage

export const getSiteSettingsSelector = createSelector(
  getCMSContentState,
  (state) => state.siteSettings,
)

export const getSystemTextSelector = (state, slug) => {
  if (
    getSystemTextState(state)[slug] &&
    getSystemTextState(state)[slug].loaded &&
    getSystemTextState(state)[slug].content
  ) {
    return getSystemTextState(state)[slug].content
  }
  return null
}
export const makeGetSystemTextSelector = () =>
  createSelector(getSystemTextSelector, (content) => content)

export const getArticleContentSelector = (state, slug) => {
  if (
    getArticleState(state)[slug] &&
    getArticleState(state)[slug].loaded &&
    getArticleState(state)[slug].content
  ) {
    return getArticleState(state)[slug].content
  }
  return null
}

export const makeGetArticleContentSelector = () =>
  createSelector(getArticleContentSelector, (content) => content)

const getArticleLoadingSelector = (state, slug) => {
  if (getArticleState(state)[slug]) {
    return !!getArticleState(state)[slug].loading
  }
  return false
}

export const makeGetArticleLoadingSelector = () =>
  createSelector(getArticleLoadingSelector, (loading) => loading)

const getArticleLoadedSelector = (state, slug) => {
  if (getArticleState(state)[slug]) {
    return !!getArticleState(state)[slug].loaded
  }
  return false
}
export const makeGetArticleLoadedSelector = () =>
  createSelector(getArticleLoadedSelector, (loading) => loading)

export const getLandingPageContentSelector = (state, slug) => {
  if (
    getLandingPageState(state)[slug] &&
    getLandingPageState(state)[slug].loaded &&
    getLandingPageState(state)[slug].content
  ) {
    return getLandingPageState(state)[slug].content
  }
  return null
}

export const makeGetLandingPageContentSelector = () =>
  createSelector(getLandingPageContentSelector, (content) => content)

const getLandingPageLoadingSelector = (state, slug) => {
  if (getLandingPageState(state)[slug]) {
    return !!getLandingPageState(state)[slug].loading
  }
  return false
}
export const makeGetLandingPageLoadingSelector = () =>
  createSelector(getLandingPageLoadingSelector, (loading) => loading)

const getLandingPageLoadedSelector = (state, slug) => {
  if (getLandingPageState(state)[slug]) {
    return !!getLandingPageState(state)[slug].loaded
  }
  return false
}
export const makeGetLandingPageLoadedSelector = () =>
  createSelector(getLandingPageLoadedSelector, (loading) => loading)
