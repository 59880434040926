import * as React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import theming from 'styled-theming'

import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

const backgroundColor = theming('buttonType', {
  primary: gigplanetTheme.component.button.primary.background,
  secondary: gigplanetTheme.component.button.secondary.background,
  secondaryNegative: gigplanetTheme.component.button.secondaryNegative.background,
  transparent: 'transparent',
  outline: gigplanetTheme.component.button.outline.background,
  abort: gigplanetTheme.component.button.abort.background,
  cancel: gigplanetTheme.component.button.cancel.background,
})

const backgroundHoverColor = theming('buttonType', {
  primary: gigplanetTheme.component.button.primary.backgroundHover,
  secondary: gigplanetTheme.component.button.secondary.backgroundHover,
  secondaryNegative: gigplanetTheme.component.button.secondaryNegative.backgroundHover,
  transparent: 'transparent',
  outline: gigplanetTheme.component.button.outline.backgroundHover,
  abort: gigplanetTheme.component.button.abort.backgroundHover,
  cancel: gigplanetTheme.component.button.cancel.backgroundHover,
})
const backgroundDisabledColor = theming('buttonType', {
  primary: gigplanetTheme.component.button.primary.backgroundDisabled,
  secondary: gigplanetTheme.component.button.secondary.backgroundDisabled,
  secondaryNegative: gigplanetTheme.component.button.secondaryNegative.backgroundDisabled,
  transparent: 'transparent',
  outline: gigplanetTheme.component.button.outline.backgroundDisabled,
  abort: gigplanetTheme.component.button.abort.backgroundDisabled,
  cancel: gigplanetTheme.component.button.cancel.backgroundDisabled,
})

const textColor = theming('buttonType', {
  primary: gigplanetTheme.component.button.primary.text,
  secondary: gigplanetTheme.component.button.secondary.text,
  secondaryNegative: gigplanetTheme.component.button.secondaryNegative.text,
  transparent: gigplanetTheme.component.button.secondary.text,
  outline: gigplanetTheme.component.button.outline.text,
  abort: gigplanetTheme.component.button.abort.text,
  text: gigplanetTheme.component.button.text.text,
  cancel: gigplanetTheme.component.button.cancel.text,
})
const textHoverColor = theming('buttonType', {
  primary: gigplanetTheme.component.button.primary.textHover,
  secondary: gigplanetTheme.component.button.secondary.textHover,
  secondaryNegative: gigplanetTheme.component.button.secondaryNegative.textHover,
  transparent: gigplanetTheme.component.button.secondary.textHover,
  outline: gigplanetTheme.component.button.outline.textHover,
  abort: gigplanetTheme.component.button.abort.textHover,
  text: gigplanetTheme.component.button.text.textHover,
  cancel: gigplanetTheme.component.button.cancel.textHover,
})
const textDisabledColor = theming('buttonType', {
  primary: gigplanetTheme.component.button.primary.textDisabled,
  secondary: gigplanetTheme.component.button.secondary.textDisabled,
  secondaryNegative: gigplanetTheme.component.button.secondaryNegative.textDisabled,
  transparent: gigplanetTheme.component.button.secondary.textDisabled,
  outline: gigplanetTheme.component.button.outline.textDisabled,
  abort: gigplanetTheme.component.button.abort.textDisabled,
  cancel: gigplanetTheme.component.button.cancel.textDisabled,
})

const borderColor = theming('buttonType', {
  primary: gigplanetTheme.component.button.primary.border,
  secondary: gigplanetTheme.component.button.secondary.border,
  secondaryNegative: gigplanetTheme.component.button.secondaryNegative.border,
  transparent: 'transparent',
  outline: gigplanetTheme.component.button.outline.border,
  abort: gigplanetTheme.component.button.abort.border,
  cancel: gigplanetTheme.component.button.cancel.border,
})
const borderHoverColor = theming('buttonType', {
  primary: gigplanetTheme.component.button.primary.borderHover,
  secondary: gigplanetTheme.component.button.secondary.borderHover,
  secondaryNegative: gigplanetTheme.component.button.secondaryNegative.borderHover,
  transparent: 'transparent',
  outline: gigplanetTheme.component.button.outline.borderHover,
  abort: gigplanetTheme.component.button.abort.borderHover,
  cancel: gigplanetTheme.component.button.cancel.borderHover,
})
const borderDisabledColor = theming('buttonType', {
  primary: gigplanetTheme.component.button.primary.borderDisabled,
  secondary: gigplanetTheme.component.button.secondary.borderDisabled,
  secondaryNegative: gigplanetTheme.component.button.secondaryNegative.borderDisabled,
  transparent: 'transparent',
  outline: gigplanetTheme.component.button.outline.borderDisabled,
  abort: gigplanetTheme.component.button.abort.borderDisabled,
  cancel: gigplanetTheme.component.button.cancel.borderDisabled,
})

const ButtonWrapper = React.forwardRef(
  (
    {
      to,
      href,
      buttonType,
      type,
      children,
      isWide,
      isLoading,
      isUppercase,
      removePaddingSides,
      ...rest
    },
    ref,
  ) => {
    if (to) {
      return (
        <Link to={to} {...rest} ref={ref}>
          {children}
        </Link>
      )
    }
    if (href) {
      return (
        <a href={href} target="_blank" rel="noopener noreferrer" ref={ref} {...rest}>
          {children}
        </a>
      )
    }
    return (
      // eslint-disable-next-line react/button-has-type
      <button type={type} ref={ref} {...rest}>
        {children}
      </button>
    )
  },
)

ButtonWrapper.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.shape({}),
    }),
  ]),
  href: PropTypes.string,
  buttonType: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
  isWide: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUppercase: PropTypes.bool,
  removePaddingSides: PropTypes.bool,
}

ButtonWrapper.defaultProps = {
  buttonType: '',
  isWide: false,
  isLoading: false,
  isUppercase: false,
  removePaddingSides: false,
}

export const StyledInput = styled(ButtonWrapper)`
  flex: 1 0 auto;
  appearance: none;
  background-color: ${gigplanetTheme.component.form.input.background};
  border: 1px solid ${gigplanetTheme.component.form.input.border};
  border-radius: 2px;
  color: ${gigplanetTheme.component.form.input.text};
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  padding: 8px 10px;
  text-align: left;
  transition: border-color 0.2s ease-in-out;
  cursor: pointer;

  ${({ isWide }) =>
    isWide &&
    css`
      width: 100%;
    `};

  &[disabled] {
    background-color: ${gigplanetTheme.component.form.input.backgroundDisabled};
    color: ${gigplanetTheme.component.form.input.textDisabled};
  }
`

export const StyledButton = styled(ButtonWrapper)`
  background: ${backgroundColor};
  /* border: 2px solid ${borderColor}; */
  border: none;
  box-shadow: inset 0 0 0 2px ${borderColor};
  color: ${textColor};
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;
  padding: 4px ${({ removePaddingSides }) => (removePaddingSides ? 0 : '20px')};

  ${({ size }) =>
    size === 'large' &&
    css`
      padding: 22px 52px;
    `};

  ${({ size }) =>
    size === 'small' &&
    css`
      padding: 4px 22px;
      font-size: 14px;
    `};

  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  word-wrap: normal;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  ${({ isUppercase }) =>
    isUppercase &&
    css`
      text-transform: uppercase;
    `};

  &[disabled] {
    background: ${backgroundDisabledColor};
    border-color: ${borderDisabledColor};
    color: ${textDisabledColor};
    cursor: default;

    &:hover {
      background: ${backgroundDisabledColor};
      border-color: ${borderDisabledColor};
      color: ${textDisabledColor};
      cursor: default;
    }
  }

  ${mediaQueries.tabletPortraitDown`
    font-size: 14px;
  `};

  &:hover {
    background: ${backgroundHoverColor};
    border-color: ${borderHoverColor};
    color: ${textHoverColor};
  }

  ${({ isWide }) =>
    isWide &&
    css`
      width: 100%;
    `};

  ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: wait;
    `};
`
export const IconAction = styled(ButtonWrapper)`
  color: #000;
`

export const Text = styled(ButtonWrapper)`
  border: 0;
  appearance: none;
  padding: 0;
  display: inline-block;
  margin: 0;
  color: ${textColor};
  background: none;
  cursor: pointer;

  ${({ isWide }) =>
    isWide &&
    css`
      width: 100%;
    `};

  ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: wait;
    `};

  ${({ size }) =>
    size === 'small' &&
    css`
      font-size: 14px;
    `};

  &:hover {
    color: ${textHoverColor};
  }
`

export const LoadingWrapper = styled.span`
  display: inline-block;
  opacity: 0;
  width: 0;
  height: 32px;
  transition: all 0.2s;

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 1;
      width: 32px;
      margin-left: 10px;
    `};
`
export const TextWrapper = styled.span`
  opacity: 1;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  word-wrap: normal;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`
