import { createSelector } from 'reselect'

import * as types from './GigRequestArtistTypes'

const initialState = {
  data: {},
  list: [],
  hasFetched: false,
  isFetching: false,
  gigRequestUnreadCount: 0,
  requestCount: 0,
  gigRequest: {
    gigRequestId: '',
    artistId: '',
    hasFetched: false,
    isFetching: false,
  },
  quote: {
    isSending: false,
    hasSent: false,
    error: '',
  },
}

const gigRequestArtistReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_UNREAD_COUNT_SUCCESS: {
      return {
        ...state,
        gigRequestUnreadCount: action.payload.count || 0,
      }
    }

    case types.RESET_UNREAD_COUNT: {
      if (state.data[`${action.gigRequestId}-${action.artistId}`]) {
        const newData = { ...state.data[`${action.gigRequestId}-${action.artistId}`] }
        if (newData.unread !== 0) {
          newData.unread = 0
          return {
            ...state,
            data: {
              ...state.data,
              [`${action.gigRequestId}-${action.artistId}`]: newData,
            },
          }
        }
      }
      return state
    }
    case types.SEND_MESSAGE: {
      if (state.data[`${action.gigRequestId}-${action.artistId}`]) {
        const newData = { ...state.data[`${action.gigRequestId}-${action.artistId}`] }
        if (!newData.timeline) {
          newData.timeline = []
        }
        newData.timeline = [
          {
            type: 'MESSAGE',
            time: action.date,
            data: {
              fromArtist: true,
              message: action.message,
            },
          },
          ...newData.timeline,
        ]
        return {
          ...state,
          data: {
            ...state.data,
            [`${action.gigRequestId}-${action.artistId}`]: newData,
          },
        }
      }
      return state
    }

    case types.FETCH_MY_ARTIST_REQUESTS_PENDING: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case types.FETCH_MY_ARTIST_REQUESTS_SUCCESS: {
      const newData = {}
      const newList = []
      if (action.payload.data && action.payload.data.requests) {
        action.payload.data.requests.forEach(gigRequest => {
          newData[`${gigRequest.id}-${gigRequest.artist.id}`] = {
            ...state.data[`${gigRequest.id}-${gigRequest.artist.id}`],
            ...gigRequest,
            artist: gigRequest.artist.id,
          }
          newList.push(`${gigRequest.id}-${gigRequest.artist.id}`)
        })
      }

      return {
        ...state,
        data: {
          ...state.data,
          ...newData,
        },
        list: newList,
        isFetching: false,
        hasFetched: true,
      }
    }
    case types.FETCH_MY_ARTIST_REQUESTS_COUNT_SUCCESS: {
      return {
        ...state,
        requestCount: action.payload.requestCount,
      }
    }
    case types.FETCH_ONE_ARTIST_REQUEST_PENDING: {
      return {
        ...state,
        gigRequest: {
          ...state.gigRequest,
          gigRequestId: action.payload.gigRequestId,
          artistId: action.payload.artistId,
          isFetching: true,
        },
      }
    }
    case types.FETCH_ONE_ARTIST_REQUEST_ERROR: {
      return {
        ...state,
        gigRequest: {
          ...state.gigRequest,
          isFetching: false,
          hasFetched: true,
        },
      }
    }

    case types.RELOAD_ONE_ARTIST_REQUEST_PENDING: {
      return {
        ...state,
        gigRequest: {
          ...state.gigRequest,
          gigRequestId: action.payload.gigRequestId,
          artistId: action.payload.artistId,
          isFetching: false,
        },
      }
    }

    case types.FETCH_ONE_ARTIST_REQUEST_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          [`${action.payload.gigRequestId}-${action.payload.artistId}`]: {
            ...state.data[`${action.payload.gigRequestId}-${action.payload.artistId}`],
            ...action.payload.data,
            artist: action.payload.data.artist.id,
          },
        },
        gigRequest: {
          ...state.gigRequest,
          gigRequestId: action.payload.gigRequestId,
          artistId: action.payload.artistId,
          isFetching: false,
          hasFetched: true,
        },
      }
    }
    default: {
      return state
    }
  }
}

export default gigRequestArtistReducer

const getGigRequestState = state => state.gigRequestArtist
const getArtistDataState = state => state.artist.data

export const isMyArtistRequestLoading = state => getGigRequestState(state).isFetching
export const hasMyArtistRequestLoaded = state => getGigRequestState(state).hasFetched
export const getMyArtistRequests = createSelector(
  [getGigRequestState, getArtistDataState],
  (gigArtistState, artistDataState) => {
    if (gigArtistState.list.length > 0) {
      return gigArtistState.list.map(id => ({
        ...gigArtistState.data[id],
        artist: artistDataState[gigArtistState.data[id].artist] || {},
      }))
    }
    return []
  },
)

export const isOneArtistRequestLoading = createSelector(
  getGigRequestState,
  gigRequestState => gigRequestState.gigRequest.isFetching,
)
export const hasOneArtistRequestLoaded = createSelector(
  getGigRequestState,
  gigRequestState => gigRequestState.gigRequest.hasFetched,
)
export const getOneArtistRequest = createSelector(
  [getGigRequestState, getArtistDataState],
  (gigRequestState, artistDataState) => {
    if (
      gigRequestState.data[
        `${gigRequestState.gigRequest.gigRequestId}-${gigRequestState.gigRequest.artistId}`
      ]
    ) {
      return {
        ...gigRequestState.data[
          `${gigRequestState.gigRequest.gigRequestId}-${gigRequestState.gigRequest.artistId}`
        ],
        artist:
          artistDataState[
            gigRequestState.data[
              `${gigRequestState.gigRequest.gigRequestId}-${gigRequestState.gigRequest.artistId}`
            ].artist
          ] || {},
      }
    }
    return {}
  },
)

export const getRequestCount = state => getGigRequestState(state).requestCount

export const getQuoteIsSending = state => getGigRequestState(state).quote.isSending
export const getQuoteHasSent = state => getGigRequestState(state).quote.hasSent

export const getUnreadCount = state => getGigRequestState(state).gigRequestUnreadCount
