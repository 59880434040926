import * as React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getCurrentUser } from '../stores/CurrentUser/CurrentUserReducer'

const mapStateToProps = (state, ownProps) => ({
  currentUser: getCurrentUser(state),
})

const withCurrentUser = Component => {
  const Extended = props => <Component {...props} />
  return compose(connect(mapStateToProps))(Extended)
}

export default withCurrentUser
