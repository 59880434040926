import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import createSagaMiddleware, { END } from 'redux-saga'

import SagaManager from './sagaManager'
import rootReducer from './reducers'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware, thunkMiddleware]
const storeEnhancers = []

const middlewareEnhancer = applyMiddleware(...middlewares)
storeEnhancers.unshift(middlewareEnhancer)

export default function configureStore(initialState = {}) {
  const store = createStore(rootReducer, initialState, compose(...storeEnhancers))
  // run sagas
  SagaManager.startSagas(sagaMiddleware)

  return store
}
