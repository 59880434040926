import PropTypes from 'prop-types'

import validateEmail from '../helpers/validate-email'
import validatePhone from '../helpers/validate-phone'
import lowercaseFirstLetter from '../helpers/lowercase-first-letter'

export const QuoteShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  members: PropTypes.number.isRequired,
  pauseCount: PropTypes.number.isRequired,
  pauseDuration: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  quoteDuration: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  terms: PropTypes.string,
})

export const GigRequestEventplannerShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
})

export const SHORT_DATE_FORMAT = 'DD.MM.YYYY'
export const DATE_FORMAT = 'DD.MM.YYYY HH:mm'
export const LONG_DATE_FORMAT = 'dddd D. MMM YYYY'
export const TIME_FORMAT = 'HH:mm'

export const SECURITY_TIPS_ARTIST_URL =
  'http://hjelp.gigplanet.no/nb/articles/1288834-sikkerhetstips'
export const SECURITY_TIPS_EVENTPLANNER_URL =
  'http://hjelp.gigplanet.no/nb/articles/1288833-sikkerhetstips'

export const HIDDEN_INFO_IN_REQUEST_ARTIST_URL =
  'http://hjelp.gigplanet.no/nb/articles/3383520-hvorfor-kan-jeg-ikke-se-arrangorens-kontaktinfo'

export const HIDDEN_INFO_IN_REQUEST_EVENTPLANNER_URL =
  'http://hjelp.gigplanet.no/nb/articles/3383546-hvorfor-kan-jeg-ikke-se-artistens-kontaktinformasjon'

export const ARTIST_RESPONSE_OPTIONS = {
  MAKE_QUOTE: 'MAKE_QUOTE',
  WITHDRAW_QUOTE: 'WITHDRAW_QUOTE',
  DECLINE_REQUEST: 'DECLINE_REQUEST',
}

export const EVENTPLANNER_RESPONSE_OPTIONS = {
  DECLINE_QUOTE: 'DECLINE_QUOTE',
}

export const GIGREQUEST_NOT_SET_ID = '-1'

export const TIMELINE_EVENTS = {
  MESSAGE: 'MESSAGE',
  REQUEST: 'REQUEST',
  REQUEST_UPDATE: 'REQUEST_UPDATE',
  QUOTE: 'QUOTE',
}

export const GIG_STATUS = {
  QUOTE_ACCEPTED: 'QUOTE_ACCEPTED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
}

export const PAYMENT_STATUS = {
  WAITING_FOR_GIG: 'WAITING_FOR_GIG',
  OPEN: 'OPEN',
  BILLED: 'BILLED',
  CANCELLED: 'CANCELLED',
}

export const REVIEW_STATUS = {
  WAITING_FOR_GIG: 'WAITING_FOR_GIG',
  NOT_REVIEWED: 'NOT_REVIEWED',
  REVIEWED: 'REVIEWED',
  IGNORED: 'IGNORED',
  CANCELLED: 'CANCELLED',
}

export const REQUEST_STATUS = {
  ACTIVE: 'ACTIVE', // ok
  COMPLETED: 'COMPLETED', // ok
  ARTIST_DECLINED: 'ARTIST_DECLINED', // ok
  EXPIRED: 'EXPIRED',
  CANCELLED: 'CANCELLED',
  QUOTE_ACCEPTED: 'QUOTE_ACCEPTED', // ok
  OTHER_QUOTE_ACCEPTED: 'OTHER_QUOTE_ACCEPTED',
  // ARCHIVED: 'ARCHIVED',
}

export const ACTIVITY_STATUS = {
  NEW_REQUEST: 'NEW_REQUEST', // ok
  EXPIRED: 'EXPIRED', // ok
  ARTIST_DECLINED: 'ARTIST_DECLINED', // ok
  MESSAGE_FROMARTIST: 'MESSAGE_FROMARTIST', // ok
  MESSAGE_TOARTIST: 'MESSAGE_TOARTIST', // ok
  QUOTE_SENT: 'QUOTE_SENT', // ok
  QUOTE_ACCEPTED: 'QUOTE_ACCEPTED', // ok
  OTHER_QUOTE_ACCEPTED: 'OTHER_QUOTE_ACCEPTED', // ok
  QUOTE_DECLINED: 'QUOTE_DECLINED', // ok
  QUOTE_WITHDRAWN: 'QUOTE_WITHDRAWN', // ok
  QUOTE_EXPIRED: 'QUOTE_EXPIRED',
  QUOTE_INVALIDATED: 'QUOTE_INVALIDATED', // ok
}

export const ARTIST_DECLINE_REASON = {
  UNDESIRABLE: 'UNDESIRABLE',
  ALREADY_BOOKED: 'ALREADY_BOOKED',
  TRAVEL_TIME: 'TRAVEL_TIME',
  OTHER: 'OTHER',
}

const paymentStatus = {
  WAITING_FOR_GIG: 'WAITING_FOR_GIG',
  OPEN: 'OPEN',
  BILLED: 'BILLED',
  CANCELLED: 'CANCELLED',
}

export const QUOTE_STATUS = {
  PENDING: 'PENDING',
  DECLINED: 'DECLINED',
  EXPIRED: 'EXPIRED', // quoteDuration passed
  INVALIDATED: 'INVALIDATED', // change in gigrequest invalidated the quote
  ACCEPTED: 'ACCEPTED',
  WITHDRAWN: 'WITHDRAWN',
  REPLACED: 'REPLACED',
}

export const ARTIST_WITHDRAW_REASON_STATUS = {
  // NOT_SUITABLE: 'NOT_SUITABLE',
  ALREADY_BOOKED: 'ALREADY_BOOKED',
  // TRAVEL_ISSUE: 'TRAVEL_ISSUE',
  OTHER: 'OTHER',
}

export const EVENTPLANNER_DECLINE_REASON_STATUS = {
  ALREADY_BOOKED: 'ALREADY_BOOKED',
  TOO_EXPENSIVE: 'TOO_EXPENSIVE',
  OTHER: 'OTHER',
}

export const getEventplannerRequestStatusColor = (requestStatus, quoteStatus) => {
  if (!requestStatus) return ''

  switch (requestStatus) {
    case REQUEST_STATUS.CANCELLED:
    case REQUEST_STATUS.ARTIST_DECLINED:
    case REQUEST_STATUS.ARCHIVED:
      return 'gray'
    case REQUEST_STATUS.QUOTE_ACCEPTED:
    case REQUEST_STATUS.COMPLETED:
    case REQUEST_STATUS.OTHER_QUOTE_ACCEPTED:
      return 'green'
    case REQUEST_STATUS.EXPIRED:
      return 'red'
    case REQUEST_STATUS.ACTIVE:
      switch (quoteStatus) {
        case QUOTE_STATUS.PENDING:
          return 'red'
        case QUOTE_STATUS.DECLINED:
        case QUOTE_STATUS.WITHDRAWN:
          return 'green'
        case QUOTE_STATUS.EXPIRED:
        case QUOTE_STATUS.INVALIDATED:
          return 'gray'
        default:
          return 'green'
      }
    default:
      return `${requestStatus}-${quoteStatus}`
  }
}
export const getArtistRequestStatusColor = (requestStatus, quoteStatus) => {
  if (!requestStatus) return 'gray'

  switch (requestStatus) {
    case REQUEST_STATUS.CANCELLED:
    case REQUEST_STATUS.ARTIST_DECLINED:
    case REQUEST_STATUS.EXPIRED:
    case REQUEST_STATUS.OTHER_QUOTE_ACCEPTED:
    case REQUEST_STATUS.ARCHIVED:
      return 'gray'
    case REQUEST_STATUS.QUOTE_ACCEPTED:
    case REQUEST_STATUS.COMPLETED:
      return 'green'
    case REQUEST_STATUS.ACTIVE:
      switch (quoteStatus) {
        case QUOTE_STATUS.PENDING:
        case QUOTE_STATUS.WITHDRAWN:
          return 'green'
        case QUOTE_STATUS.DECLINED:
          return 'red'
        case QUOTE_STATUS.EXPIRED:
        case QUOTE_STATUS.INVALIDATED:
          return 'gray'
        default:
          return 'red'
      }
    default:
      return `${requestStatus}-${quoteStatus}`
  }
}

export const getEventplannerRequestStatusHeading = (requestStatus, quoteStatus) => {
  if (!requestStatus) return ''

  switch (requestStatus) {
    case REQUEST_STATUS.COMPLETED:
      return 'Arrangement gjennomført'
    case REQUEST_STATUS.CANCELLED:
      return 'Forespørsel kansellert'
    case REQUEST_STATUS.ARTIST_DECLINED:
      return 'Forespørsel avvist av artist'
    case REQUEST_STATUS.QUOTE_ACCEPTED:
      return 'Tilbud akseptert'
    case REQUEST_STATUS.EXPIRED:
      return 'Forespørsel løpt ut på dato'
    case REQUEST_STATUS.OTHER_QUOTE_ACCEPTED:
      return 'Annen tilbyder valgt'
    case REQUEST_STATUS.ARCHIVED:
      return 'Forespørsel arkivert'
    case REQUEST_STATUS.ACTIVE:
      switch (quoteStatus) {
        case QUOTE_STATUS.PENDING:
          return 'Tilbud mottatt'
        case QUOTE_STATUS.DECLINED:
          return 'Tilbud avvist'
        case QUOTE_STATUS.EXPIRED:
          return 'Tilbud utløpt'
        case QUOTE_STATUS.INVALIDATED:
          return 'Forespørsel endret'
        case QUOTE_STATUS.WITHDRAWN:
          return 'Tilbud trukket tilbake'
        default:
          return 'Forespørsel sendt'
      }
    default:
      return `${requestStatus}-${quoteStatus}`
  }
}
export const getArtistRequestStatusHeading = (requestStatus, quoteStatus) => {
  if (!requestStatus) return ''

  switch (requestStatus) {
    case REQUEST_STATUS.COMPLETED:
      return 'Spillejobb gjennomført'
    case REQUEST_STATUS.CANCELLED:
      return 'Forespørsel kansellert'
    case REQUEST_STATUS.ARTIST_DECLINED:
      return 'Forespørsel avvist'
    case REQUEST_STATUS.QUOTE_ACCEPTED:
      return 'Spillejobb booket. Gratulerer!'
    case REQUEST_STATUS.EXPIRED:
      return 'Forespørsel utløpt'
    case REQUEST_STATUS.OTHER_QUOTE_ACCEPTED:
      return 'Forespørsel tapt'
    case REQUEST_STATUS.ARCHIVED:
      return 'Forespørsel arkivert'
    case REQUEST_STATUS.ACTIVE:
      switch (quoteStatus) {
        case QUOTE_STATUS.PENDING:
          return 'Tilbud sendt'
        case QUOTE_STATUS.DECLINED:
          return 'Tilbud avvist'
        case QUOTE_STATUS.EXPIRED:
          return 'Tilbud utløpt'
        case QUOTE_STATUS.INVALIDATED:
          return 'Forespørsel endret'
        case QUOTE_STATUS.WITHDRAWN:
          return 'Tilbud trukket tilbake'
        default:
          return 'Forespørsel mottatt'
      }
    default:
      return `${requestStatus}-${quoteStatus}`
  }
}

export const getRequestStatusName = status => {
  switch (status) {
    case REQUEST_STATUS.ACTIVE:
      return 'Aktiv'
    case REQUEST_STATUS.ARTIST_DECLINED:
      return 'Avvslått av artist'
    case REQUEST_STATUS.EXPIRED:
      return 'Utløpt'
    case REQUEST_STATUS.INVALIDATED:
      return 'Ugyldig'
    case REQUEST_STATUS.QUOTE_ACCEPTED:
      return 'Tilbud akseptert'
    case REQUEST_STATUS.OTHER_QUOTE_ACCEPTED:
      return 'Annet tilbud akseptert'
    case REQUEST_STATUS.ARCHIVED:
      return 'Arkivert'
    default:
      return status
  }
}
export const getEventplannerDeclineReasonName = status => {
  switch (status) {
    case EVENTPLANNER_DECLINE_REASON_STATUS.ALREADY_BOOKED:
      return 'Har allerede booket andre'
    case EVENTPLANNER_DECLINE_REASON_STATUS.TOO_EXPENSIVE:
      return 'Prisen ble for høy'
    case EVENTPLANNER_DECLINE_REASON_STATUS.OTHER:
      return 'Annet'
    default:
      return status
  }
}

export const getArtistWithdrawReasonName = status => {
  switch (status) {
    case ARTIST_WITHDRAW_REASON_STATUS.NOT_SUITABLE:
      return 'Ikke lenger god match '
    case ARTIST_WITHDRAW_REASON_STATUS.ALREADY_BOOKED:
      return 'Vi har fått en annen spillejobb'
    case ARTIST_WITHDRAW_REASON_STATUS.TRAVEL_ISSUE:
      return 'Oppstått problemer med reise'
    case ARTIST_WITHDRAW_REASON_STATUS.OTHER:
      return 'Annet'
    default:
      return status
  }
}

export const getArtistDeclineReasonName = status => {
  switch (status) {
    case ARTIST_DECLINE_REASON.UNDESIRABLE:
      return 'Vi gjør ikke denne type spillejobber'
    case ARTIST_DECLINE_REASON.ALREADY_BOOKED:
      return 'Vi er allerede booket'
    case ARTIST_DECLINE_REASON.TRAVEL_TIME:
      return 'Det blir for lang reise'
    case ARTIST_DECLINE_REASON.OTHER:
      return 'Annet'
    default:
      return status
  }
}

export const getQuoteStatusName = (status, isArtist) => {
  if (isArtist) {
    switch (status) {
      case QUOTE_STATUS.PENDING:
        return 'Nytt tilbud'
      case QUOTE_STATUS.REPLACED:
        return 'Tilbud oppdatert'
      case QUOTE_STATUS.INVALIDATED:
        return 'Tilbud ikke lenger gyldig'
      case QUOTE_STATUS.WITHDRAWN:
        return 'Tilbud trukket tilbake'
      case QUOTE_STATUS.DECLINED:
        return 'Tilbud avvist'
      case QUOTE_STATUS.EXPIRED:
        return 'Tilbud utløpt'
      case QUOTE_STATUS.ACCEPTED:
        return 'Tilbud akseptert'
      default:
        return status
    }
  } else {
    switch (status) {
      case QUOTE_STATUS.PENDING:
        return 'Nytt tilbud'
      case QUOTE_STATUS.REPLACED:
        return 'Tilbud oppdatert'
      case QUOTE_STATUS.INVALIDATED:
        return 'Tilbud ikke lenger gyldig'
      case QUOTE_STATUS.WITHDRAWN:
        return 'Tilbud trukket tilbake'
      case QUOTE_STATUS.DECLINED:
        return 'Tilbud avvist'
      case QUOTE_STATUS.EXPIRED:
        return 'Tilbud utløpt'
      case QUOTE_STATUS.ACCEPTED:
        return 'Tilbud akseptert'
      default:
        return status
    }
  }
}

export const getActivityStatusName = (status, isArtist) => {
  if (isArtist) {
    switch (status) {
      case ACTIVITY_STATUS.NEW_REQUEST:
        return 'Ny forespørsel'
      case ACTIVITY_STATUS.EXPIRED:
        return 'Forespørsel utløpt'
      case ACTIVITY_STATUS.MESSAGE_FROMARTIST:
        return 'Sendt melding'
      case ACTIVITY_STATUS.MESSAGE_TOARTIST:
        return 'Ny melding'
      case ACTIVITY_STATUS.QUOTE_SENT:
        return 'Tilbud sendt'
      case ACTIVITY_STATUS.QUOTE_WITHDRAWN:
        return 'Tilbud trukket tilbake'
      case ACTIVITY_STATUS.ARTIST_DECLINED:
        return 'Avslått'
      case ACTIVITY_STATUS.QUOTE_DECLINED:
        return 'Tilbud avvist'
      case ACTIVITY_STATUS.QUOTE_INVALIDATED:
        return 'Tilbud ugyldig'
      case ACTIVITY_STATUS.QUOTE_EXPIRED:
        return 'Tilbud utløpt'
      case ACTIVITY_STATUS.QUOTE_ACCEPTED:
        return 'Tilbud akseptert'
      case ACTIVITY_STATUS.OTHER_QUOTE_ACCEPTED:
        return 'Tilbud fra annen tilbyder akseptert'
      default:
        return status
    }
  } else {
    switch (status) {
      case ACTIVITY_STATUS.NEW_REQUEST:
        return 'Sendt forespørsel'
      case ACTIVITY_STATUS.EXPIRED:
        return 'Forespørsel utløpt'
      case ACTIVITY_STATUS.MESSAGE_FROMARTIST:
        return 'Melding mottatt'
      case ACTIVITY_STATUS.MESSAGE_TOARTIST:
        return 'Sendt melding'
      case ACTIVITY_STATUS.QUOTE_SENT:
        return 'Tilbud mottatt'
      case ACTIVITY_STATUS.QUOTE_WITHDRAWN:
        return 'Tilbud trukket tilbake'
      case ACTIVITY_STATUS.ARTIST_DECLINED:
        return 'Ville ikke gi tilbud'
      case ACTIVITY_STATUS.QUOTE_DECLINED:
        return 'Du har takket nei'
      case ACTIVITY_STATUS.QUOTE_INVALIDATED:
        return 'Tilbud ugyldig'
      case ACTIVITY_STATUS.QUOTE_EXPIRED:
        return 'Tilbud utløpt'
      case ACTIVITY_STATUS.QUOTE_ACCEPTED:
        return 'Tilbud akseptert'
      case ACTIVITY_STATUS.OTHER_QUOTE_ACCEPTED:
        return 'Annet tilbud akseptert'
      default:
        return status
    }
  }
}

export const ARTIST_EVENT_GIGREQUEST_URL = 'gigrequest/event'
export const ARTIST_CHOOSE_GIGREQUEST_URL = 'gigrequest/artists'
export const ARTIST_LIMIT = 5
export const FIND_MORE_ARTISTS_URL = '/s'

export const includedOptionInGigRequestForm = {
  SCENELIGHTS: 'scenelights',
  SOUNDSYSTEM: 'soundsystem',
}

export const includedOptionsInGigRequestForm = [
  includedOptionInGigRequestForm.SCENELIGHTS,
  includedOptionInGigRequestForm.SOUNDSYSTEM,
]

export const gigRequestType = {
  PRIVATE: 'private_event',
  BUSINESS: 'business_event',
}

export const getIncludedOptionName = type => {
  switch (type) {
    case includedOptionInGigRequestForm.SCENELIGHTS:
      return 'Scenelys'
    case includedOptionInGigRequestForm.SOUNDSYSTEM:
      return 'Lydanlegg/PA'

    default:
      return type
  }
}
export const getIncludedOptionSentence = (included, includePause = true) => {
  let includedText = ''
  if ((included || []).indexOf(includedOptionInGigRequestForm.SOUNDSYSTEM) !== -1) {
    includedText += lowercaseFirstLetter(
      getIncludedOptionName(includedOptionInGigRequestForm.SOUNDSYSTEM),
    )
  }

  if ((included || []).indexOf(includedOptionInGigRequestForm.SCENELIGHTS) !== -1) {
    if (includedText && includePause) {
      includedText += ', '
    } else if (includedText) {
      includedText += ' og '
    }

    includedText += includedText.length > 0 ? ' ' : ''

    includedText += lowercaseFirstLetter(
      getIncludedOptionName(includedOptionInGigRequestForm.SCENELIGHTS),
    )
  }
  if (includedText && includePause) {
    includedText += ' og '
  }

  if (includePause) {
    includedText += 'pausemusikk'
  }

  return includedText
}

export const gigRequestTypes = [gigRequestType.PRIVATE, gigRequestType.BUSINESS]

export const getGigRequestFormArtists = artists => {
  let artistText = ''
  if (artists && artists.length > 0) {
    if (artists.length > 1) {
      artists.forEach((artist, i) => {
        artistText += artist.name
        if (i === artists.length - 2) {
          artistText += ' og '
        } else if (i < artists.length - 2) {
          artistText += ', '
        }
      })
    } else {
      artistText = artists[0].name
    }
  }
  return artistText
}

export const validateEventForm = event => {
  const errorMessages = {}

  if (event.eventType && !event.eventType.eventType) {
    errorMessages['eventtypecategory'] = 'Velg type arrangør'
  }
  if (event.eventType && !event.eventType.sub) {
    errorMessages['event-type'] = 'Velg type arrangement'
  }
  if (!event.audienceCount) {
    errorMessages['audience-count'] = 'Fyll ut antall gjester'
  }
  if (!event.startTime || !event.endTime) {
    errorMessages['event-date'] = 'Velg dato og tid'
  }
  return errorMessages
}

export const validateEventDetailsForm = event => {
  const errorMessages = {}
  if (!event.venue) {
    errorMessages.venue = 'Fyll ut lokalet'
  }
  if (!event.city) {
    errorMessages['event-city'] = 'Fyll ut by'
  }
  return errorMessages
}

export const validateRequesterForm = (requester, event) => {
  const errorMessages = {}
  const isBusiness = event.eventType.eventType === gigRequestType.BUSINESS

  if (isBusiness && !requester.organization) {
    errorMessages.organization = 'Fyll ut organisasjonsnavn'
  }

  if (!requester.phone) {
    errorMessages.phone = 'Fyll ut mobil'
  } else if (requester.phone.length < 8) {
    errorMessages.phone = 'Mobilnummeret må bestå av minst 8 siffer'
  } else if (!validatePhone(requester.phone)) {
    errorMessages.phone = 'Bruk et gyldig telefonnummer'
  }
  if (!requester.email) {
    errorMessages.email = 'Fyll ut e-post'
  } else if (!validateEmail(requester.email)) {
    errorMessages.email = 'Fyll ut gyldig e-post'
  }
  return errorMessages
}

export const translateServerErrorMessage = errorCode => {
  switch (errorCode) {
    case 'gigrequest-starttimenotinfuture':
      return 'Du kan ikke endre en forespørsel hvor datoen ikke er i fremtiden'
    case 'gigrequest-noartistsadded':
      return 'Du har ikke valgt en artist'
    case 'gigrequest-toomanyartists':
      return 'For mange artister. Du kan kun sende forespørsel til 5 artister pr arrangement'
    default:
      return errorCode
  }
}
