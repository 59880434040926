import { createSelector } from 'reselect'

import {
  FETCH_LOCATIONS_PENDING,
  FETCH_LOCATIONS_FULFILLED,
  FETCH_LOCATIONS_REJECTED,
  FETCH_LOCATIONS_CHILDREN_PENDING,
  FETCH_LOCATIONS_CHILDREN_FULFILLED,
  FETCH_LOCATIONS_CHILDREN_REJECTED,
} from './LocationsActions'

const initialState = {
  counties: [],
  isFetching: false,
  hasFetched: false,
}

const locationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOCATIONS_PENDING: {
      return {
        ...state,
        counties: [],
        isFetching: true,
      }
    }
    case FETCH_LOCATIONS_FULFILLED: {
      return {
        ...state,
        counties: action.payload.data,
        isFetching: false,
        hasFetched: true,
      }
    }
    case FETCH_LOCATIONS_REJECTED: {
      return {
        ...state,
        counties: [],
        isFetching: false,
        hasFetched: true,
      }
    }

    case FETCH_LOCATIONS_CHILDREN_PENDING: {
      return {
        ...state,
        isFetching: true,
      }
    }

    case FETCH_LOCATIONS_CHILDREN_FULFILLED: {
      const newCounties = [...state.counties]
      const newCountiesIndex = newCounties.findIndex(
        county => county.id === action.payload.parentId,
      )
      if (newCountiesIndex !== -1) {
        newCounties[newCountiesIndex] = {
          ...newCounties[newCountiesIndex],
          children: action.payload.data,
        }
      }

      return {
        ...state,
        counties: newCounties,
        isFetching: false,
      }
    }

    case FETCH_LOCATIONS_CHILDREN_REJECTED: {
      return {
        ...state,
        isFetching: false,
      }
    }
    default: {
      return state
    }
  }
}

export default locationsReducer

const locationsState = state => state.locations

export const hasCountiesLoadedSelector = state => locationsState(state).hasFetched
export const getCounties = createSelector(
  locationsState,
  state => state.counties,
)
