import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects'

import { getJSON, postJSON } from '../../libs/data'
import * as types from './GigRequestArtistTypes'
import * as actions from './GigRequestArtistActions'

function* getMyArtistRequests() {
  const url = '/api/v1/gigRequestArtist/myRequests'
  yield put(actions.fetchMyArtistRequestsPendingAction())
  try {
    const gigRequests = yield call(getJSON, url)
    yield put(actions.fetchMyArtistRequestsSuccessAction(gigRequests))
  } catch (error) {
    yield put(actions.fetchMyArtistRequestsErrorAction(error))
  }
}

function* getOneRequest(action) {
  const url = '/api/v1/gigRequestArtist/getOneRequest'
  yield put(actions.fetchOneArtistRequestPendingAction(action.gigRequestId, action.artistId))
  try {
    const gigRequest = yield call(postJSON, url, {
      gigRequestId: action.gigRequestId,
      artistId: action.artistId,
    })
    yield put(
      actions.fetchOneArtistRequestSuccessAction(action.gigRequestId, action.artistId, gigRequest),
    )
  } catch (error) {
    yield put(actions.fetchOneArtistRequestErrorAction(error))
  }
}

function* reloadOneRequest(action) {
  const url = '/api/v1/gigRequestArtist/getOneRequest'
  yield put(actions.reloadOneArtistRequestPendingAction(action.gigRequestId, action.artistId))
  try {
    const gigRequest = yield call(postJSON, url, {
      gigRequestId: action.gigRequestId,
      artistId: action.artistId,
    })
    yield put(
      actions.fetchOneArtistRequestSuccessAction(action.gigRequestId, action.artistId, gigRequest),
    )
  } catch (error) {
    yield put(actions.fetchOneArtistRequestErrorAction(error))
  }
}

function* getMyArtistRequestsCount() {
  const url = '/api/v1/gigRequestArtist/myRequestCount'
  yield put(actions.fetchMyArtistRequestsCountPendingAction())
  try {
    const data = yield call(getJSON, url)
    yield put(actions.fetchMyArtistRequestsCountSuccessAction(data.requestCount))
  } catch (error) {
    yield put(actions.fetchMyArtistRequestsCountErrorAction(error))
  }
}

function* updateUnreadCount() {
  try {
    const data = yield call(getJSON, '/api/v1/gigrequestArtist/unreadCount')
    yield put({ type: types.UPDATE_UNREAD_COUNT_SUCCESS, payload: { count: data.count } })
  } catch (error) {
    // DO NOTHING
  }
}

export default function* gigRequestArtistsSagas() {
  yield all([
    takeLatest(types.GET_MY_ARTIST_REQUESTS, getMyArtistRequests),
    takeLatest(types.GET_MY_ARTIST_REQUESTS_COUNT, getMyArtistRequestsCount),
    takeEvery(types.GET_ONE_ARTIST_REQUEST, getOneRequest),
    takeEvery(types.RELOAD_ONE_ARTIST_REQUEST, reloadOneRequest),
    takeLatest(types.UPDATE_UNREAD_COUNT, updateUnreadCount),
  ])
}
