import { getJSON } from '../../libs/data'

import * as types from './AuthTypes'

export const resetFormsAction = () => ({ type: types.RESET_FORMS })

export const updateRegisterFormAction = form => ({
  type: types.UPDATE_REGISTER_FORM,
  payload: {
    ...form,
  },
})

export const createUserAction = successUrl => ({ type: types.CREATE_USER, successUrl })
export const createUserPendingAction = () => ({ type: types.CREATE_USER_PENDING })
export const createUserErrorAction = errorMessages => ({
  type: types.CREATE_USER_ERROR,
  payload: { errorMessages },
})
export const createUserSuccessAction = () => ({ type: types.CREATE_USER_SUCCESS })

export const updateLoginFormAction = form => ({
  type: types.UPDATE_LOGIN_FORM,
  payload: {
    ...form,
  },
})
export const loginAction = successUrl => ({ type: types.LOGIN_USER, successUrl })
export const loginPendingAction = () => ({ type: types.LOGIN_USER_PENDING })
export const loginErrorAction = (errorMessages, verifyError) => ({
  type: types.LOGIN_USER_ERROR,
  payload: { errorMessages, verifyError },
})
export const loginSuccessAction = () => ({ type: types.LOGIN_USER_SUCCESS })

export const updateForgotPasswordFormAction = form => ({
  type: types.UPDATE_FORGOT_PASSWORD_FORM,
  payload: {
    ...form,
  },
})
export const sendForgotPasswordAction = () => ({ type: types.FORGOT_PASSWORD })
export const sendForgotPasswordPendingAction = () => ({ type: types.FORGOT_PASSWORD_PENDING })
export const sendForgotPasswordErrorAction = errorMessages => ({
  type: types.FORGOT_PASSWORD_ERROR,
  payload: { errorMessages },
})
export const sendForgotPasswordSuccessAction = () => ({ type: types.FORGOT_PASSWORD_SUCCESS })

export const updateNewPasswordFormAction = form => ({
  type: types.UPDATE_NEW_PASSWORD_FORM,
  payload: {
    ...form,
  },
})
export const newPasswordAction = () => ({ type: types.NEW_PASSWORD })
export const newPasswordPendingAction = () => ({ type: types.NEW_PASSWORD_PENDING })
export const newPasswordErrorAction = errorMessages => ({
  type: types.NEW_PASSWORD_ERROR,
  payload: { errorMessages },
})
export const newPasswordSuccessAction = () => ({ type: types.NEW_PASSWORD_SUCCESS })

export const updateVerifyEmailFormAction = form => ({
  type: types.UPDATE_VERIFY_EMAIL_FORM,
  payload: {
    ...form,
  },
})
export const sendVerifyEmailAction = () => ({ type: types.VERIFY_EMAIL })
export const sendVerifyEmailPendingAction = () => ({ type: types.VERIFY_EMAIL_PENDING })
export const sendVerifyEmailErrorAction = errorMessages => ({
  type: types.VERIFY_EMAIL_ERROR,
  payload: { errorMessages },
})
export const sendVerifyEmailSuccessAction = () => ({ type: types.VERIFY_EMAIL_SUCCESS })

export const checkAuthDataAction = (req = {}) => dispatch =>
  getJSON('/api/v1/auth/authedData', { req }).then(json => {
    dispatch({ type: types.GET_AUTH_DATA, payload: { ...json } })
  })
