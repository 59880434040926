/* global dataLayer, amplitude */

const dataLayerExists = () => typeof dataLayer !== 'undefined'
const amplitudeExists = () => typeof amplitude !== 'undefined'

export const initAnalytics = (uniqueId = '', userId = '') => {
  if (uniqueId) {
    if (dataLayerExists()) {
      dataLayer.push({
        userID: uniqueId,
        loggedIn: true,
      })
    }
  } else if (dataLayerExists()) {
    dataLayer.push({
      loggedIn: false,
    })
  }

  if (userId) {
    if (amplitudeExists()) {
      amplitude.getInstance().setUserId(userId)
    }
  }
}

export const trackEvent = (category, action, label = '', value = 0) => {
  if (dataLayerExists()) {
    dataLayer.push({
      event: 'gp_event',
      category,
      action,
      label,
      value,
    })
  } else {
    console.log('Track', 'Event', category, action)
  }
}

export const trackError = (error, info) => {
  trackEvent('error', 'js-error', '', error)
}

export const trackPageView = url => {
  if (dataLayerExists()) {
    setTimeout(() => {
      // ref: https://stackoverflow.com/questions/49322314/react-spa-gtm-analytics-react-helmet-previous-page-title
      dataLayer.push({
        event: 'PageView',
        PageView: url,
      })
    }, 0)
  } else {
    console.log('Track', 'Pageview', url)
  }
}
