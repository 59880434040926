import { rgba, darken, lighten } from 'polished'

const gigplanetTheme = {
  color: {
    lightestGray: '#f5f5f5',
    lightGray: '#f4f4f4',
    lightMediumGray: '#d9d9d9',
    mediumGray: '#4f4e4e',
    gray: '#cccccc',
    darkGray: '#484848',
    darkerGray: '#2b2d2e',
    white: '#ffffff',
    black: '#000000',
    primary: '#6D27D8',
    darkPrimary: rgba('#390087', 0.8),
    purple: '#390087',
    darkPurple: rgba('#390087', 0.8),
    lightRed: '#ff5a5f',
    red: '#e00007',
    orange: '#f68b1f',
    ok: '#779900',
    abort: '#ff5a5f',
  },
  measure: {
    maxWidth: '75rem',
  },
  spacing: {
    xxsmall: '0.25rem',
    xsmall: '0.5rem',
    small: '1rem',
    medium: '2rem',
    large: '3rem',
    xlarge: '4rem',
  },
  zIndex: {
    header: 991,
    controlpanelMenu: 991,
    popup: 500,
    formElements: 100,
    profileMenu: 200,
    favoriteMenu: 200,
    artistSelection: 200,
    progressbar: 600,
    modalWindow: 992,
    submitting: 990,
    dropdown: 650,
    dropdownContent: 750,
    audioplayer: 10,
    requestGigrequest: 9,
    imageGallery: 992,
    imageGalleryButtons: 992,
    profileAdminInfo: 98,
  },
}

gigplanetTheme.element = {
  default: {
    text: gigplanetTheme.color.darkGray,
    heading: gigplanetTheme.color.darkGray,
    border: '#dce0e0',
    ok: '#93bb05',
    abort: '#ff5a5f',
    abortHover: '#e00007',
    label: '#373335',
  },
  timeline: {
    text: gigplanetTheme.color.darkGray,
    background: '#edefed',
    systemBackground: '#edefed',
    activeBackground: '#fff',
    border: '#dce0e0',
  },
  artistCard: {
    background: gigplanetTheme.color.white,
    text: gigplanetTheme.color.darkGray,
    border: '#979797',
    feature: gigplanetTheme.color.white,
    featureBackground: 'rgb(109,39,216)',
    featureGradient: 'linear-gradient(180deg, rgba(109,39,216,1) 0%, rgba(57,0,135,1) 100%)',
    genresColor: '#575757',
  },
  favoriteHeader: {
    border: '#dce0e0',
    background: '#f4f4f4',
  },
  tag: {
    text: '#23282d',
    background: '#F5F5F5',
  },
  header: {
    background: gigplanetTheme.color.lightGray,
  },
  footer: {
    background: gigplanetTheme.color.white,
    border: gigplanetTheme.color.mediumGray,
    text: gigplanetTheme.color.black,
    link: '#6D27D8',
    linkHover: gigplanetTheme.color.primary,
  },
  siteNavigation: {
    background: '#e0faee',
  },
  frontpage: {
    heading: gigplanetTheme.color.darkGray,
    intro: gigplanetTheme.color.darkGray,
  },
  systemMessage: {
    border: '#dce0e0',
  },
  gigrequest: {
    background: '#f4f4f4',
  },
}

gigplanetTheme.component = {
  textSeparator: {
    border: '#dce0e0',
    text: '#484848',
  },
  searchFilter: {
    selectedElement: {
      background: '#fafafa',
      text: '#565a5c',
      icon: '#565a5c',
    },
  },
  headings: {
    h1: {
      text: gigplanetTheme.element.default.heading,
    },
    h2: {
      text: gigplanetTheme.element.default.heading,
    },
  },
  form: {
    shared: {
      errorText: '#ff5a5f',
      errorBorder: '#e00007',
    },
    label: {
      text: '#373335',
      textDisabled: '#bbb',
    },
    select: {
      text: gigplanetTheme.color.darkGray,
      border: '#c4c4c4',
      background: '#fff',
    },
    input: {
      text: gigplanetTheme.color.darkGray,
      textDisabled: '#565a5c',
      border: '#c4c4c4',
      background: '#fff',
      backgroundDisabled: '#eff3f6',
      validated: '#93bb05',
      helpText: '#82888a',
      limit: '#82888a',
    },
    checkbox: {
      border: '#c4c4c4',
      background: '#fff',
    },
    radio: {
      color: gigplanetTheme.color.purple,
      border: '#c4c4c4',
      background: '#fff',
    },
  },
  ratingStars: {
    empty: '#dce0e0',
    filled: '#32E08E',
    filledBorder: '#00AA5A',
  },
  progressbar: {
    default: {
      background: '#c4c4c4',
      text: '#fff',
      bar: '#93bb05',
    },
    success: {
      background: '#c4c4c4',
      text: '#fff',
      bar: '#0C5A70',
    },
  },
  button: {
    primary: {
      text: gigplanetTheme.color.white,
      textHover: gigplanetTheme.color.white,
      textDisabled: '#C49FFF',
      border: 'transparent',
      borderHover: 'transparent',
      borderDisabled: '#C49FFF',
      background: 'linear-gradient(180deg, rgba(109,39,216,1) 0%, rgba(57,0,135,1) 100%)',
      backgroundHover: '#924DFF',
      backgroundDisabled: '#E7D8FF',
    },
    secondary: {
      text: gigplanetTheme.color.purple,
      textHover: gigplanetTheme.color.darkPurple,
      textDisabled: gigplanetTheme.color.lightMediumGray,
      border: gigplanetTheme.color.purple,
      borderHover: gigplanetTheme.color.darkPurple,
      borderDisabled: gigplanetTheme.color.lightMediumGray,
      background: gigplanetTheme.color.white,
      backgroundHover: gigplanetTheme.color.lightGray,
      backgroundDisabled: gigplanetTheme.color.lightGray,
    },
    outline: {
      text: gigplanetTheme.color.darkGray,
      textHover: gigplanetTheme.color.darkerGray,
      textDisabled: lighten(0.2, gigplanetTheme.color.darkGray),
      border: gigplanetTheme.color.gray,
      borderHover: gigplanetTheme.color.darkerGray,
      borderDisabled: lighten(0.2, gigplanetTheme.color.gray),
      background: 'transparent',
      backgroundHover: 'transparent',
      backgroundDisabled: 'transparent',
    },
    secondaryNegative: {
      text: gigplanetTheme.color.white,
      textHover: gigplanetTheme.color.white,
      textDisabled: 'rgba(255, 255, 255, 0.45)',
      border: gigplanetTheme.color.gray,
      borderHover: gigplanetTheme.color.darkerGray,
      borderDisabled: 'rgba(255, 255, 255, 0.45)',
      background: 'rgba(0, 0, 0, 0.5)',
      backgroundHover: 'rgba(255, 255, 255, 0.35)',
      backgroundDisabled: 'transparent',
    },
    text: {
      text: gigplanetTheme.color.purple,
      textHover: gigplanetTheme.color.darkPurple,
      textDisabled: gigplanetTheme.color.lightMediumGray,
      background: 'transparent',
      backgroundHover: 'transparent',
      backgroundDisabled: 'transparent',
    },

    abort: {
      text: gigplanetTheme.color.white,
      textHover: gigplanetTheme.color.lightGray,
      textDisabled: darken(0.8, gigplanetTheme.color.lightGray),
      border: gigplanetTheme.color.abort,
      borderHover: gigplanetTheme.color.lightRed,
      borderDisabled: darken(0.8, gigplanetTheme.color.lightRed),
      background: gigplanetTheme.color.abort,
      backgroundHover: gigplanetTheme.color.lightRed,
      backgroundDisabled: darken(0.8, gigplanetTheme.color.lightRed),
    },
    cancel: {
      text: gigplanetTheme.color.darkGray,
      textHover: gigplanetTheme.color.darkerGray,
      textDisabled: gigplanetTheme.color.lightGray,
      border: gigplanetTheme.color.white,
      borderHover: gigplanetTheme.color.lightGray,
      borderDisabled: gigplanetTheme.color.lightGray,
      background: gigplanetTheme.color.white,
      backgroundHover: gigplanetTheme.color.lightGray,
      backgroundDisabled: gigplanetTheme.color.lightGray,
    },
  },
}

export default gigplanetTheme
