/* global FormData */
import { getJSON, postData, postJSON } from '../../libs/data'
import {
  FETCH_ARTIST_PENDING,
  FETCH_ARTIST_FULFILLED,
  FETCH_ARTIST_REJECTED,
  RESET_SERVER_FETCHED,
  FETCH_USERARTISTS_PENDING,
  FETCH_USERARTISTS_FULFILLED,
  FETCH_USERARTISTS_REJECTED,
  ADD_FAVORITE,
  REMOVE_FAVORITE,
  FETCH_FAVORITE_ARTISTS_PENDING,
  FETCH_FAVORITE_ARTISTS_FULFILLED,
  FETCH_FAVORITE_ARTISTS_REJECTED,
  FETCH_MATCHING_ARTISTS_PENDING,
  FETCH_MATCHING_ARTISTS_FULFILLED,
  FETCH_MATCHING_ARTISTS_REJECTED,
  FETCH_FRONTPAGE_ARTISTS_PENDING,
  FETCH_FRONTPAGE_ARTISTS_FULFILLED,
  FETCH_FRONTPAGE_ARTISTS_REJECTED, // eslint-disable-line
  FETCH_JOIN_ARTISTS_PENDING,
  FETCH_JOIN_ARTISTS_FULFILLED,
  FETCH_JOIN_ARTISTS_REJECTED, // eslint-disable-line
  SET_SEARCH_DATA,
  RESET_SEARCH_DATA,
  FETCH_SEARCH_PENDING,
  FETCH_SEARCH_FULFILLED,
  FETCH_SEARCH_REJECTED,
  FETCH_ARTIST_PAGEVIEW_STATS_PENDING,
  FETCH_ARTIST_PAGEVIEW_STATS_FULFILLED,
  FETCH_ARTIST_PAGEVIEW_STATS_REJECTED,
  ADD_FAVORITE_FULFILLED,
  ADD_FAVORITE_ERROR,
  REMOVE_FAVORITE_FULFILLED,
  REMOVE_FAVORITE_ERROR,
} from './ArtistTypes'

import {
  getSearchData,
  getFavoriteArtistIds,
  getArtist as getArtistSelector,
  isServerFetched as artistIsServerFetchedSelector,
  getFrontpageArtists,
  hasFavoriteArtistsLoaded,
} from './ArtistReducer'

/*
  JOIN
*/

export const fetchJoinArtistsAction = artists => dispatch => {
  const url = '/api/v1/artist/search'
  const params = {
    artists,
  }
  dispatch({ type: FETCH_JOIN_ARTISTS_PENDING })

  return postJSON(url, params).then(
    json => {
      dispatch({
        type: FETCH_JOIN_ARTISTS_FULFILLED,
        payload: {
          data: json,
          receivedAt: Date.now(),
        },
      })
    },
    error => {
      dispatch({ type: FETCH_JOIN_ARTISTS_REJECTED })
    },
  )
}

/*
 * action creators
 */

export const resetSearchDataAction = () => dispatch => {
  dispatch({
    type: RESET_SEARCH_DATA,
  })
  dispatch(updateUrlAction())
}

export const setSearchDataAction = data => dispatch => {
  dispatch({
    type: SET_SEARCH_DATA,
    data,
  })
  dispatch(updateUrlAction())
}

/*
    Keep url in sync. Called each time you update filter or location(bounds)
*/
const updateUrlAction = () => (dispatch, getState) => {
  const url = window.location.pathname
  let params = ''
  const paramsArray = []
  const searchData = getSearchData(getState())
  if (searchData.artistType) {
    paramsArray.push({ artistType: searchData.artistType })
  }
  if (searchData.location) {
    paramsArray.push({ location: searchData.location })
  }
  if (searchData.eventType) {
    paramsArray.push({ eventType: searchData.eventType })
  }
  if (searchData.category) {
    paramsArray.push({ category: searchData.category })
  }

  if (searchData.genre) {
    paramsArray.push({ genre: searchData.genre })
  }

  if (searchData.name) {
    paramsArray.push({ name: searchData.name })
  }

  if (searchData.priceFrom) {
    paramsArray.push({ priceFrom: searchData.priceFrom })
  }

  if (searchData.priceTo) {
    paramsArray.push({ priceTo: searchData.priceTo })
  }

  if (searchData.sortField) {
    paramsArray.push({ sortField: searchData.sortField })
  }

  if (searchData.sortDir) {
    paramsArray.push({ sortDir: searchData.sortDir })
  }

  let isFirst = true
  paramsArray.forEach(param => {
    Object.keys(param).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(param, key)) {
        params += `${isFirst ? '?' : '&'}${key}=${param[key]}`
        isFirst = false
      }
    })
  })

  const currentUrl = window.location.pathname + window.location.search
  const nextUrl = url + params

  if (currentUrl !== nextUrl) {
    window.history.replaceState({}, 'Søk', nextUrl)
  }
}

export const restoreUrlAction = (req, query, limit, offset) => dispatch => {
  const filter = {
    category: '',
    eventType: '',
    artistType: '',
    location: '',
    name: '',
    genre: '',
    priceFrom: 0,
    priceTo: 75000,
    sortField: 'relevance',
    sortDir: 'desc',
  }

  if (
    query.artistType ||
    query.location ||
    query.eventType ||
    query.category ||
    query.genre ||
    query.name ||
    query.priceFrom ||
    query.priceTo ||
    query.sortField ||
    query.sortDir
  ) {
    if (query.artistType) filter.artistType = query.artistType
    if (query.location) filter.location = query.location
    if (query.eventType) filter.eventType = query.eventType
    if (query.category) filter.category = query.category
    if (query.genre) filter.genre = query.genre
    if (query.name) filter.name = query.name
    if (query.priceFrom) filter.priceFrom = parseInt(query.priceFrom, 10)
    if (query.priceTo) filter.priceTo = parseInt(query.priceTo, 10)
    if (query.sortField) filter.sortField = query.sortField
    if (query.sortDir) filter.sortDir = query.sortDir
  }
  dispatch(setSearchDataAction(filter))
  dispatch(searchAction(req, limit, offset))
}

export const searchAction = (req = {}, limit = 6, offset = 0, overRideParams = null) => (
  dispatch,
  getState,
) => {
  const searchData = overRideParams || getSearchData(getState())
  const params = {}
  if (searchData.artistType) {
    params.artistType = searchData.artistType
  }
  if (searchData.location) {
    params.location = searchData.location
  }
  if (searchData.eventType) {
    params.eventType = searchData.eventType
  }
  if (searchData.category) {
    params.category = searchData.category
  }

  if (searchData.genre) {
    params.genre = searchData.genre
  }

  if (searchData.name) {
    params.name = searchData.name
  }

  if (searchData.priceFrom) {
    params.priceFrom = searchData.priceFrom
  }
  if (searchData.priceTo) {
    params.priceTo = searchData.priceTo
  }

  if (searchData.sortField) {
    params.sortField = searchData.sortField
  }

  if (searchData.sortDir) {
    params.sortDir = searchData.sortDir
  }

  params.offset = offset
  params.limit = limit

  const url = `/api/v1/artist/search`

  dispatch({ type: FETCH_SEARCH_PENDING })
  return postJSON(url, params).then(
    json => {
      dispatch({
        type: FETCH_SEARCH_FULFILLED,
        payload: {
          data: json,
          receivedAt: Date.now(),
          offset,
        },
      })
    },
    error => {
      dispatch({ type: FETCH_SEARCH_REJECTED })
    },
  )
}

export const fetchArtistPageviewStatsActions = (artistId, days = 30) => dispatch => {
  const url = `/api/v1/artistAdmin/pageViewStats?id=${artistId}&days=${days}`
  dispatch({
    type: FETCH_ARTIST_PAGEVIEW_STATS_PENDING,
    payload: {
      artistId,
    },
  })
  getJSON(url).then(
    json => {
      dispatch({
        type: FETCH_ARTIST_PAGEVIEW_STATS_FULFILLED,
        payload: {
          data: json,
          artistId,
          receivedAt: Date.now(),
        },
      })
    },
    () => {
      dispatch({
        type: FETCH_ARTIST_PAGEVIEW_STATS_REJECTED,
      })
    },
  )
}

export const fetchFavoriteArtistsAction = (req = {}) => dispatch => {
  const url = '/api/v1/artist/getFavorites'
  dispatch({ type: FETCH_FAVORITE_ARTISTS_PENDING })
  return getJSON(url, { req }).then(
    json => {
      dispatch({
        type: FETCH_FAVORITE_ARTISTS_FULFILLED,
        payload: {
          data: json,
          receivedAt: Date.now(),
        },
      })
    },
    error => {
      dispatch({ type: FETCH_FAVORITE_ARTISTS_REJECTED })
    },
  )
}

export const fetchMatchingArtistsAction = (id, limit) => async dispatch => {
  dispatch({ type: FETCH_MATCHING_ARTISTS_PENDING })
  try {
    const result = await getJSON(`/api/v1/artist/matchingArtists?id=${id}&limit=${limit}`)
    dispatch({
      type: FETCH_MATCHING_ARTISTS_FULFILLED,
      payload: {
        data: result,
        receivedAt: Date.now(),
      },
    })
  } catch (error) {
    dispatch({ type: FETCH_MATCHING_ARTISTS_REJECTED })
  }
}

export const fetchFavoriteArtistsIfNeededAction = () => (dispatch, getState) => {
  if (!hasFavoriteArtistsLoaded(getState())) {
    dispatch(fetchFavoriteArtistsAction())
  }
}

export const addFavoriteAction = id => (dispatch, getState) => {
  const url = '/api/v1/artist/addFavorite'
  const data = new FormData()
  data.append('id', id)
  const favorites = getFavoriteArtistIds(getState())
  if (!favorites[id]) {
    dispatch({
      type: ADD_FAVORITE,
      payload: {
        id,
      },
    })

    postData(url, data).then(
      () => {
        dispatch({
          type: ADD_FAVORITE_FULFILLED,
          id,
        })
        dispatch(fetchFavoriteArtistsAction())
      },
      () => {
        dispatch({
          type: ADD_FAVORITE_ERROR,
        })
      },
    )
  } else {
    dispatch({
      type: ADD_FAVORITE_ERROR,
      message: 'Duplicate',
    })
  }
}

export const removeFavoriteAction = id => dispatch => {
  const url = '/api/v1/artist/removeFavorite'
  const data = new FormData()
  data.append('id', id)
  dispatch({
    type: REMOVE_FAVORITE,
    payload: {
      id,
    },
  })
  postData(url, data).then(
    () => {
      dispatch({
        type: REMOVE_FAVORITE_FULFILLED,
        id,
      })
      dispatch(fetchFavoriteArtistsAction())
    },
    () => {
      dispatch({
        type: REMOVE_FAVORITE_ERROR,
      })
    },
  )
}

export const fetchFrontpageArtists = (
  req,
  limit = 6,
  offset = 0,
  serverFetched = false,
) => dispatch => {
  const url = `/api/v1/artist/getAll?limit=${limit}&offset=${offset}`
  dispatch({ type: FETCH_FRONTPAGE_ARTISTS_PENDING })

  return getJSON(url, { req, ttl: 60 * 5 }).then(json => {
    dispatch({
      type: FETCH_FRONTPAGE_ARTISTS_FULFILLED,
      payload: {
        data: json,
        receivedAt: Date.now(),
        offset,
        serverFetched,
      },
    })
  })
}
export const fetchFrontpageArtistsIfNeeded = (limit = 6, offset = 0) => (dispatch, getState) => {
  const frontpageArtists = getFrontpageArtists(getState())
  if (frontpageArtists && frontpageArtists.length > 0) {
    return
  }

  dispatch(fetchFrontpageArtists({}, limit, offset))
}

export const fetchUserArtists = (req = {}) => (dispatch, getState) => {
  // Most likly deprecated
  const url = '/api/v1/artistAdmin/myArtists'
  const currentUser = getState().currentUser.data

  if (Object.keys(req).length === 0 && (!currentUser || !currentUser.id)) {
    dispatch({ type: FETCH_USERARTISTS_REJECTED })
    return null
  }

  dispatch({ type: FETCH_USERARTISTS_PENDING })
  return getJSON(url, { req }).then(
    json => {
      dispatch({
        type: FETCH_USERARTISTS_FULFILLED,
        payload: {
          data: json,
          receivedAt: Date.now(),
        },
      })
    },
    error => {
      dispatch({ type: FETCH_USERARTISTS_REJECTED })
    },
  )
}

export const fetchOneArtistBySlug = (req, slug, serverFetched = false) => dispatch => {
  const url = `/api/v1/artist/getOne?slug=${slug}`
  dispatch({ type: FETCH_ARTIST_PENDING })

  return getJSON(url, { req }).then(
    json => {
      dispatch({
        type: FETCH_ARTIST_FULFILLED,
        payload: {
          data: json,
          receivedAt: Date.now(),
          serverFetched,
        },
      })
    },
    error => {
      dispatch({ type: FETCH_ARTIST_REJECTED })
    },
  )
}

export const fetchOneArtistBySlugIfNeeded = (req = {}, slug) => (dispatch, getState) => {
  const artist = getArtistSelector(getState())
  const isServerFetched = artistIsServerFetchedSelector(getState())
  if (artist && artist.slug === slug) {
    if (isServerFetched) {
      return dispatch({ type: RESET_SERVER_FETCHED })
    }
  }
  return dispatch(fetchOneArtistBySlug(req, slug))
}
