export const GET_MY_ARTIST_REQUESTS = 'GIGREQUEST_ARTIST/GET_MY_REQUESTS'
export const FETCH_MY_ARTIST_REQUESTS_PENDING = 'GIGREQUEST_ARTIST/FETCH_MY_REQUESTS_PENDING'
export const FETCH_MY_ARTIST_REQUESTS_SUCCESS = 'GIGREQUEST_ARTIST/FETCH_MY_REQUESTS_SUCCESS'
export const FETCH_MY_ARTIST_REQUESTS_ERROR = 'GIGREQUEST_ARTIST/FETCH_MY_REQUESTS_ERROR'

export const GET_ONE_ARTIST_REQUEST = 'GIGREQUEST_ARTIST/GET_ONE_REQUEST'
export const FETCH_ONE_ARTIST_REQUEST_PENDING = 'GIGREQUEST_ARTIST/FETCH_ONE_REQUEST_PENDING'
export const FETCH_ONE_ARTIST_REQUEST_SUCCESS = 'GIGREQUEST_ARTIST/FETCH_ONE_REQUEST_SUCCESS'
export const FETCH_ONE_ARTIST_REQUEST_ERROR = 'GIGREQUEST_ARTIST/FETCH_ONE_REQUEST_ERROR'

export const RELOAD_ONE_ARTIST_REQUEST = 'GIGREQUEST_ARTIST/RELOAD_ONE_REQUEST'
export const RELOAD_ONE_ARTIST_REQUEST_PENDING = 'GIGREQUEST_ARTIST/RELOAD_ONE_REQUEST_PENDING'

export const GET_MY_ARTIST_REQUESTS_COUNT = 'GIGREQUEST_ARTIST/GET_MY_ARTIST_REQUEST_COUNT'
export const FETCH_MY_ARTIST_REQUESTS_COUNT_PENDING =
  'GIGREQUEST_ARTIST/FETCH_MY_ARTIST_REQUEST_COUNT_PENDING'
export const FETCH_MY_ARTIST_REQUESTS_COUNT_SUCCESS =
  'GIGREQUEST_ARTIST/FETCH_MY_ARTIST_REQUEST_COUNT_SUCCESS'
export const FETCH_MY_ARTIST_REQUESTS_COUNT_ERROR =
  'GIGREQUEST_ARTIST/FETCH_MY_ARTIST_REQUEST_COUNT_ERROR'

export const SEND_MESSAGE = 'GIGREQUEST_ARTIST/SEND_MESSAGE'

export const RESET_UNREAD_COUNT = 'GIGREQUEST_ARTIST/RESET_UNREAD_COUNT'

export const UPDATE_UNREAD_COUNT = 'GIGREQUEST_ARTIST/UPDATE_UNREAD_COUNT'
export const UPDATE_UNREAD_COUNT_SUCCESS = 'GIGREQUEST_ARTIST/UPDATE_UNREAD_COUNT_SUCCESS'
