import styled from 'styled-components'
import gigplanetTheme from '../../styles/gigplanetTheme'
import mediaQueries from '../../styles/mediaQueries'

export const Container = styled.div`
  max-width: 400px;
  padding: ${gigplanetTheme.spacing.small};
  border: 1px solid ${gigplanetTheme.element.default.border};
  margin: ${gigplanetTheme.spacing.medium} auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mediaQueries.mobile`
    border: 0;
  `}
`

export const LogoWrapper = styled.div`
  svg {
    height: 40px;
    width: 140px;
  }
`

export const Text = styled.p`
  text-align: center;
  line-height: 20px;
  margin: 40px 0 40px 0;
`

export const Spacer = styled.div`
  height: 20px;
  width: 100%;
`

export const FrontpageLink = styled.a`
  color: ${gigplanetTheme.color.primary};
`
