import * as types from './AuthTypes'

const initialState = {
  login: {
    isLoggingIn: false,
    hasLoggedIn: false,
    errorMessages: [],
    verifyError: false,
    form: {
      email: '',
      password: '',
    },
  },
  register: {
    isSaving: false,
    hasSaved: false,
    errorMessages: [],
    form: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
  },
  forgotPassword: {
    form: {
      email: '',
    },
    isSending: false,
    hasSent: false,
  },
  newPassword: {
    form: {
      password: '',
    },
    isSaving: false,
    hasSaved: false,
  },
  verifyEmail: {
    form: {
      email: '',
    },
    isSending: false,
    hasSent: false,
  },
  auth: {
    email: '',
    provider: '',
  },
}

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_AUTH_DATA: {
      return {
        ...state,
        auth: {
          email: action.payload.email,
          provider: action.payload.provider,
        },
      }
    }
    case types.RESET_FORMS: {
      return {
        ...state,
        register: {
          ...initialState.register,
        },
        login: {
          ...initialState.login,
        },
        password: {
          ...initialState.password,
        },
      }
    }
    case types.UPDATE_REGISTER_FORM: {
      return {
        ...state,
        register: {
          ...state.register,
          form: {
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            email: action.payload.email,
            password: action.payload.password,
          },
        },
        login: {
          ...state.login,
          form: {
            ...state.login.form,
            email: action.payload.email,
          },
        },
        forgotPassword: {
          ...state.forgotPassword,
          form: {
            ...state.forgotPassword.form,
            email: action.payload.email,
          },
        },
      }
    }
    case types.CREATE_USER_PENDING: {
      return {
        ...state,
        register: {
          ...state.register,
          isSaving: true,
        },
      }
    }
    case types.CREATE_USER_ERROR: {
      return {
        ...state,
        register: {
          ...state.register,
          errorMessages: action.payload.errorMessages,
          isSaving: false,
        },
      }
    }
    case types.CREATE_USER_SUCCESS: {
      return {
        ...state,
        register: {
          ...state.register,
          isSaving: false,
          hasSaved: true,
        },
      }
    }

    case types.UPDATE_LOGIN_FORM: {
      return {
        ...state,
        login: {
          ...state.login,
          form: {
            email: action.payload.email,
            password: action.payload.password,
          },
        },
        register: {
          ...state.register,
          form: {
            ...state.register.form,
            email: action.payload.email,
          },
        },
        forgotPassword: {
          ...state.forgotPassword,
          form: {
            ...state.forgotPassword.form,
            email: action.payload.email,
          },
        },
      }
    }
    case types.LOGIN_USER_PENDING: {
      return {
        ...state,
        login: {
          ...state.login,
          isLoggingIn: true,
        },
      }
    }
    case types.LOGIN_USER_ERROR: {
      return {
        ...state,
        login: {
          ...state.login,
          errorMessages: action.payload.errorMessages,
          verifyError: action.payload.verifyError,
          isLoggingIn: false,
        },
      }
    }
    case types.LOGIN_USER_SUCCESS: {
      return {
        ...state,
        login: {
          ...state.login,
          isLoggingIn: false,
          hasLoggedIn: true,
        },
      }
    }

    case types.UPDATE_FORGOT_PASSWORD_FORM: {
      return {
        ...state,
        register: {
          ...state.register,
          form: {
            email: action.payload.email,
          },
        },
        login: {
          ...state.login,
          form: {
            ...state.login.form,
            email: action.payload.email,
          },
        },
        forgotPassword: {
          ...state.forgotPassword,
          form: {
            ...state.forgotPassword.form,
            email: action.payload.email,
          },
        },
      }
    }

    case types.FORGOT_PASSWORD_PENDING: {
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          isSending: true,
        },
      }
    }
    case types.FORGOT_PASSWORD_ERROR: {
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          errorMessages: action.payload.errorMessages,
          isSending: false,
        },
      }
    }
    case types.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          isSending: false,
          hasSent: true,
        },
      }
    }
    case types.UPDATE_NEW_PASSWORD_FORM: {
      return {
        ...state,
        newPassword: {
          ...state.newPassword,
          form: {
            password: action.payload.password,
          },
        },
      }
    }

    case types.NEW_PASSWORD_PENDING: {
      return {
        ...state,
        newPassword: {
          ...state.newPassword,
          isSaving: true,
        },
      }
    }
    case types.NEW_PASSWORD_ERROR: {
      return {
        ...state,
        newPassword: {
          ...state.newPassword,
          errorMessages: action.payload.errorMessages,
          isSaving: false,
        },
      }
    }
    case types.NEW_PASSWORD_SUCCESS: {
      return {
        ...state,
        newPassword: {
          ...state.newPassword,
          isSaving: false,
          hasSaved: true,
        },
      }
    }
    case types.UPDATE_VERIFY_EMAIL_FORM: {
      return {
        ...state,
        verifyEmail: {
          ...state.verifyEmail,
          form: {
            ...state.verifyEmail.form,
            email: action.payload.email,
          },
        },
      }
    }

    case types.VERIFY_EMAIL_PENDING: {
      return {
        ...state,
        verifyEmail: {
          ...state.verifyEmail,
          isSending: true,
        },
      }
    }
    case types.VERIFY_EMAIL_ERROR: {
      return {
        ...state,
        verifyEmail: {
          ...state.verifyEmail,
          errorMessages: action.payload.errorMessages,
          isSending: false,
        },
      }
    }
    case types.VERIFY_EMAIL_SUCCESS: {
      return {
        ...state,
        verifyEmail: {
          ...state.verifyEmail,
          isSending: false,
          hasSent: true,
        },
      }
    }
    default:
      return state
  }
}

export default AuthReducer

const getAuthState = state => state.auth
// createSelector
export const getRegisterForm = state => getAuthState(state).register.form
export const getRegisterIsSaving = state => getAuthState(state).register.isSaving
export const getRegisterHasSaved = state => getAuthState(state).register.hasSaved
export const getRegisterErrorMessages = state => getAuthState(state).register.errorMessages

export const getLoginForm = state => getAuthState(state).login.form
export const getLoginIsLoggingIn = state => getAuthState(state).login.isLoggingIn
export const getLoginHasLoggedIn = state => getAuthState(state).login.hasLoggedIn
export const getLoginErrorMessages = state => getAuthState(state).login.errorMessages
export const getLoginHasVerifyError = state => getAuthState(state).login.verifyError

export const getForgotPasswordForm = state => getAuthState(state).forgotPassword.form
export const getForgotPasswordIsSending = state => getAuthState(state).forgotPassword.isSending
export const getForgotPasswordHasSent = state => getAuthState(state).forgotPassword.hasSent
export const getForgotPasswordErrorMessages = state =>
  getAuthState(state).forgotPassword.errorMessages

export const getNewPasswordForm = state => getAuthState(state).newPassword.form
export const getNewPasswordIsSaving = state => getAuthState(state).newPassword.isSaving
export const getNewPasswordHasSaved = state => getAuthState(state).newPassword.hasSaved
export const getNewPasswordErrorMessages = state => getAuthState(state).newPassword.errorMessages

export const getVerifyEmailForm = state => getAuthState(state).verifyEmail.form
export const getVerifyEmailIsSending = state => getAuthState(state).verifyEmail.isSending
export const getVerifyEmailHasSent = state => getAuthState(state).verifyEmail.hasSent
export const getVerifyEmailErrorMessages = state => getAuthState(state).verifyEmail.errorMessages

export const getEmail = state => getAuthState(state).auth.email
export const getProvider = state => getAuthState(state).auth.provider
