/* global FormData */

import { getJSON, postData, postJSON } from '../../libs/data'

export const FETCH_REPERTOIRE_SONG_LOOKUP_PENDING = 'FETCH_REPERTOIRE_SONG_LOOKUP_PENDING'
export const FETCH_REPERTOIRE_SONG_LOOKUP_FULFILLED = 'FETCH_REPERTOIRE_SONG_LOOKUP_FULFILLED'
export const FETCH_REPERTOIRE_SONG_LOOKUP_REJECTED = 'FETCH_REPERTOIRE_SONG_LOOKUP_REJECTED'

export const FETCH_REPERTOIRE_ARTIST_LOOKUP_PENDING = 'FETCH_REPERTOIRE_ARTIST_LOOKUP_PENDING'
export const FETCH_REPERTOIRE_ARTIST_LOOKUP_FULFILLED = 'FETCH_REPERTOIRE_ARTIST_LOOKUP_FULFILLED'
export const FETCH_REPERTOIRE_ARTIST_LOOKUP_REJECTED = 'FETCH_REPERTOIRE_ARTIST_LOOKUP_REJECTED'

export const SAVE_REPERTOIRE_ARTIST = 'SAVE_REPERTOIRE_ARTIST'
export const SAVE_REPERTOIRE_SONG = 'SAVE_REPERTOIRE_SONG'

export const saveRepertoireSongAction = (song) => ({
  type: SAVE_REPERTOIRE_SONG,
  data: song,
})

export const createArtist = (name) => (dispatch) => {
  const url = '/api/v1/repertoire/createArtist'
  const data = new FormData()
  data.append('artist', name)
  postData(url, data).then((json) => {
    dispatch({
      type: SAVE_REPERTOIRE_ARTIST,
      data: json,
    })
  })
}

export const getSongLookup = (name, artistId) => (dispatch) => {
  dispatch({ type: FETCH_REPERTOIRE_SONG_LOOKUP_PENDING })

  return postJSON('/api/v1/repertoire/lookupSong', {
    song: name,
    artistId: artistId,
  }).then(
    (json) => {
      dispatch({
        type: FETCH_REPERTOIRE_SONG_LOOKUP_FULFILLED,
        payload: {
          data: json,
          receivedAt: Date.now(),
        },
      })
    },
    (error) => {
      dispatch({ type: FETCH_REPERTOIRE_ARTIST_LOOKUP_REJECTED })
    },
  )
}

export const getArtistLookup = (name) => (dispatch) => {
  dispatch({ type: FETCH_REPERTOIRE_ARTIST_LOOKUP_PENDING })
  return postJSON('/api/v1/repertoire/lookupArtist', {
    artist: name,
  }).then(
    (json) => {
      dispatch({
        type: FETCH_REPERTOIRE_ARTIST_LOOKUP_FULFILLED,
        payload: {
          data: json,
          receivedAt: Date.now(),
        },
      })
    },
    (error) => {
      dispatch({ type: FETCH_REPERTOIRE_ARTIST_LOOKUP_REJECTED })
    },
  )
}
