import { createSelector } from 'reselect'
import { COOKIE_WARNING_ACCEPTED } from '../../models/cookies'
import { SET_COOKIE, SET_ALL_COOKIES } from './types'

const initialState = {}

export default function cookiesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ALL_COOKIES: {
      return {
        ...state,
        ...action.payload.cookies,
      }
    }

    case SET_COOKIE: {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }
    }
    default:
      return state
  }
}

const getCurrentState = state => state.cookies

export const getCookieWarningAcceptedSelectors = createSelector(
  getCurrentState,
  state => state[COOKIE_WARNING_ACCEPTED] || '',
)
