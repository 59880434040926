import { createSelector } from 'reselect'
import {
  FETCH_ARTIST_PENDING,
  FETCH_ARTIST_FULFILLED,
  FETCH_ARTIST_REJECTED,
  RESET_SERVER_FETCHED,
  FETCH_USERARTISTS_PENDING,
  FETCH_USERARTISTS_FULFILLED,
  FETCH_USERARTISTS_REJECTED,
  ADD_FAVORITE,
  REMOVE_FAVORITE,
  FETCH_FAVORITE_ARTISTS_PENDING,
  FETCH_FAVORITE_ARTISTS_FULFILLED,
  FETCH_FAVORITE_ARTISTS_REJECTED,
  FETCH_MATCHING_ARTISTS_PENDING,
  FETCH_MATCHING_ARTISTS_FULFILLED,
  FETCH_MATCHING_ARTISTS_REJECTED,
  FETCH_FRONTPAGE_ARTISTS_PENDING,
  FETCH_FRONTPAGE_ARTISTS_FULFILLED,
  FETCH_FRONTPAGE_ARTISTS_REJECTED, // eslint-disable-line
  FETCH_JOIN_ARTISTS_PENDING,
  FETCH_JOIN_ARTISTS_FULFILLED,
  FETCH_JOIN_ARTISTS_REJECTED, // eslint-disable-line
  SET_SEARCH_DATA,
  RESET_SEARCH_DATA,
  FETCH_SEARCH_PENDING,
  FETCH_SEARCH_FULFILLED,
  FETCH_SEARCH_REJECTED,
  FETCH_ARTIST_PAGEVIEW_STATS_PENDING,
  FETCH_ARTIST_PAGEVIEW_STATS_FULFILLED,
} from './ArtistTypes'

import * as gigRequestArtistTypes from '../GigRequestArtist/GigRequestArtistTypes'
import * as gigRequestEventplannerTypes from '../gig-request-eventplanner/types'

const initialState = {
  data: {},
  statsData: {},
  artist: {
    id: '',
    isFetching: false,
    hasFetched: false,
    serverFetched: false,
  },
  userArtists: {
    list: [],
    isFetching: false,
    hasFetched: false,
  },
  favoriteArtists: {
    list: [],
    isFetching: false,
    hasFetched: false,
    firstFavorite: true,
  },
  matchingArtists: {
    list: [],
    isFetching: false,
    hasFetched: false,
    firstFavorite: true,
  },
  searchArtists: {
    list: [],
    filters: {},
    totalCount: 0,
    isFetching: false,
    hasFetched: false,
    isMore: false,
  },
  frontpageArtists: {
    list: [],
    isFetching: false,
    hasFetched: false,
    isMore: false,
  },
  joinArtists: {
    list: [],
    isFetching: false,
    hasFetched: false,
  },
  searchData: {
    category: '',
    eventType: '',
    artistType: '',
    location: '',
    name: '',
    genre: '',
    priceFrom: 0,
    priceTo: 75000,
    sortField: 'relevance',
    sortDir: 'desc',
  },
}

const artistReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_SEARCH_DATA: {
      return {
        ...state,
        searchData: {
          ...initialState.searchData,
        },
      }
    }
    case SET_SEARCH_DATA: {
      return {
        ...state,
        searchData: {
          ...state.searchData,
          ...action.data,
        },
      }
    }
    case RESET_SERVER_FETCHED: {
      return {
        ...state,
        artist: {
          ...state.artist,
          serverFetched: false,
        },
      }
    }
    case gigRequestEventplannerTypes.FETCH_MY_EVENTPLANNER_REQUESTS_SUCCESS: {
      const data = {}
      if (action.payload.data && action.payload.data.requests) {
        action.payload.data.requests.forEach(gigRequest => {
          if (gigRequest.artists && gigRequest.artists.length > 0) {
            gigRequest.artists.forEach(artistInfo => {
              data[artistInfo.artist.id] = {
                ...state.data[artistInfo.artist.id],
                ...artistInfo.artist,
                images: [
                  ...(state.data[artistInfo.artist.id] && state.data[artistInfo.artist.id].images
                    ? state.data[artistInfo.artist.id].images
                    : artistInfo.artist.images), // If no images before. Use new image
                ],
              }
            })
          }
        })
        return {
          ...state,
          data: {
            ...state.data,
            ...data,
          },
        }
      }
      return state
    }
    case gigRequestEventplannerTypes.FETCH_ONE_EVENTPLANNER_REQUEST_SUCCESS: {
      const data = {}
      if (
        action.payload.data &&
        action.payload.data.artists &&
        action.payload.data.artists.length > 0
      ) {
        action.payload.data.artists.forEach(artistInfo => {
          data[artistInfo.artist.id] = {
            ...state.data[artistInfo.artist.id],
            ...artistInfo.artist,
          }
        })
        return {
          ...state,
          data: {
            ...state.data,
            ...data,
          },
        }
      }
      return state
    }
    case gigRequestEventplannerTypes.FETCH_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST_SUCCESS: {
      const data = {}
      if (action.payload.data && action.payload.data.artist && action.payload.data.artist.artist) {
        data[action.payload.data.artist.artist.id] = {
          ...state.data[action.payload.data.artist.artist.id],
          ...action.payload.data.artist.artist,
        }
        return {
          ...state,
          data: {
            ...state.data,
            ...data,
          },
        }
      }
      return state
    }
    case gigRequestArtistTypes.FETCH_MY_ARTIST_REQUESTS_SUCCESS: {
      const data = {}
      if (action.payload.data && action.payload.data.requests) {
        action.payload.data.requests.forEach(gigRequest => {
          if (gigRequest.artist && gigRequest.artist.id) {
            data[gigRequest.artist.id] = {
              ...state.data[gigRequest.artist.id],
              ...gigRequest.artist,
            }
          }
        })
        return {
          ...state,
          data: {
            ...state.data,
            ...data,
          },
        }
      }
      return state
    }
    case gigRequestArtistTypes.FETCH_ONE_ARTIST_REQUEST_SUCCESS: {
      if (action.payload.data.artist && action.payload.data.artist.id) {
        const data = {}
        data[action.payload.data.artist.id] = {
          ...state.data[action.payload.data.artist.id],
          ...action.payload.data.artist,
        }
        return {
          ...state,
          data: {
            ...state.data,
            ...data,
          },
        }
      }
      return state
    }
    case FETCH_ARTIST_PAGEVIEW_STATS_PENDING: {
      if (action.payload.artistId) {
        const newStatsData = {}
        let oldStatsData = {}
        if (state.statsData && state.statsData[action.payload.artistId]) {
          oldStatsData = { ...state.statsData[action.payload.artistId] }
        }
        newStatsData[action.payload.artistId] = {
          ...oldStatsData,
          fetching: false,
          hasFetched: true,
        }

        return {
          ...state,
          statsData: {
            ...state.statsData,
            ...newStatsData,
          },
        }
      }
      return state
    }
    case FETCH_ARTIST_PAGEVIEW_STATS_FULFILLED: {
      if (action.payload.artistId) {
        const newStatsData = {}
        let oldStatsData = {}
        if (state.statsData && state.statsData[action.payload.artistId]) {
          oldStatsData = { ...state.statsData[action.payload.artistId] }
        }
        newStatsData[action.payload.artistId] = {
          ...oldStatsData,
          data: action.payload.data,
          fetching: true,
          hasFetched: false,
        }

        return {
          ...state,
          statsData: {
            ...state.statsData,
            ...newStatsData,
          },
        }
      }
      return state
    }
    case FETCH_ARTIST_PENDING: {
      return {
        ...state,
        artist: {
          ...state.artist,
          hasFetched: false,
          isFetching: true,
        },
      }
    }
    case FETCH_ARTIST_FULFILLED: {
      const artist = {}
      if (action.payload.data && action.payload.data.id) {
        // in case of server failure
        artist[action.payload.data.id] = action.payload.data
        return {
          ...state,
          artist: {
            ...state.artist,
            id: action.payload.data.id,
            isFetching: false,
            hasFetched: true,
            serverFetched: action.payload.serverFetched,
          },
          data: {
            ...state.data,
            ...artist,
          },
        }
      }

      return state
    }
    case FETCH_ARTIST_REJECTED: {
      return {
        ...state,
        artist: {
          ...state.artist,
          id: '',
          isFetching: false,
          hasFetched: true,
        },
      }
    }
    case FETCH_FRONTPAGE_ARTISTS_PENDING: {
      return {
        ...state,
        frontpageArtists: {
          ...state.frontpageArtists,
          isFetching: true,
        },
      }
    }
    case FETCH_FRONTPAGE_ARTISTS_FULFILLED: {
      const data = {}
      const newList = []
      if (action.payload.data && action.payload.data.artists) {
        action.payload.data.artists.forEach(artist => {
          data[artist.id] = { ...state.data[artist.id], ...artist }
          newList.push(artist.id)
        })
      }

      let list = newList
      if (action.payload.offset > 0) {
        list = [...state.frontpageArtists.list, ...list]
      }
      return {
        ...state,
        frontpageArtists: {
          ...state.frontpageArtists,
          list,
          isFetching: false,
          hasFetched: true,
          isMore: action.payload.data ? action.payload.data.isMore : false,
          serverFetched: action.payload.serverFetched,
        },
        data: {
          ...state.data,
          ...data,
        },
      }
    }
    case FETCH_FRONTPAGE_ARTISTS_REJECTED: {
      return {
        ...state,
        frontpageArtists: {
          ...state.frontpageArtists,
          data: [],
          isFetching: false,
          hasFetched: true,
        },
      }
    }
    case FETCH_JOIN_ARTISTS_PENDING: {
      return {
        ...state,
        joinArtists: {
          ...state.joinArtists,
          isFetching: true,
        },
      }
    }
    case FETCH_JOIN_ARTISTS_FULFILLED: {
      const data = {}
      const newList = []
      if (action.payload.data && action.payload.data.artists) {
        action.payload.data.artists.forEach(artist => {
          data[artist.id] = { ...state.data[artist.id], ...artist }
          newList.push(artist.id)
        })
      }
      return {
        ...state,
        joinArtists: {
          ...state.joinArtists,
          list: newList,
          isFetching: false,
          hasFetched: true,
        },
        data: {
          ...state.data,
          ...data,
        },
      }
    }
    case FETCH_JOIN_ARTISTS_REJECTED: {
      return {
        ...state,
        joinArtists: {
          ...state.joinArtists,
          data: [],
          isFetching: false,
          hasFetched: true,
        },
      }
    }
    case FETCH_SEARCH_PENDING: {
      return {
        ...state,
        searchArtists: {
          ...state.searchArtists,
          isFetching: true,
        },
      }
    }
    case FETCH_SEARCH_FULFILLED: {
      const data = {}
      const newList = []
      if (action.payload.data && action.payload.data.artists) {
        action.payload.data.artists.forEach(artist => {
          data[artist.id] = { ...state.data[artist.id], ...artist }
          newList.push(artist.id)
        })
      }

      let list = newList
      if (action.payload.offset > 0) {
        list = [...state.searchArtists.list, ...list]
      }
      return {
        ...state,
        searchArtists: {
          ...state.searchArtists,
          list,
          filters: action.payload.data ? action.payload.data.filters : {},
          totalCount: action.payload.data ? action.payload.data.count : 0,
          isFetching: false,
          hasFetched: true,
          isMore: action.payload.data ? action.payload.data.isMore : false,
        },
        data: {
          ...state.data,
          ...data,
        },
      }
    }
    case FETCH_SEARCH_REJECTED: {
      return {
        ...state,
        searchArtists: {
          ...state.searchArtists,
          data: [],
          isFetching: false,
          hasFetched: true,
        },
      }
    }
    case FETCH_USERARTISTS_PENDING: {
      return {
        ...state,
        userArtists: {
          ...state.userArtists,
          isFetching: true,
        },
      }
    }
    case FETCH_USERARTISTS_FULFILLED: {
      const data = {}
      const list = []
      if (action.payload.data) {
        action.payload.data.forEach(artist => {
          data[artist.id] = { ...state.data[artist.id], ...artist }
          list.push(artist.id)
        })
      }
      return {
        ...state,
        userArtists: {
          ...state.userArtists,
          list,
          hasFetched: true,
          isFetching: false,
        },
        data: {
          ...state.data,
          ...data,
        },
      }
    }
    case FETCH_USERARTISTS_REJECTED: {
      return {
        ...state,
        userArtists: {
          ...state.userArtists,
          hasFetched: true,
          isFetching: false,
        },
      }
    }
    case ADD_FAVORITE: {
      const index = state.favoriteArtists.list.findIndex(e => e === action.payload.id)
      if (index === -1) {
        const list = [...state.favoriteArtists.list, [action.payload.id]]
        return {
          ...state,
          favoriteArtists: {
            ...state.favoriteArtists,
            list,
            firstFavorite: false,
          },
        }
      }
      return state
    }
    case REMOVE_FAVORITE: {
      const index = state.favoriteArtists.list.findIndex(e => e === action.payload.id)
      if (index > -1) {
        return {
          ...state,
          favoriteArtists: {
            ...state.favoriteArtists,
            list: [
              ...state.favoriteArtists.list.slice(0, index),
              ...state.favoriteArtists.list.slice(index + 1),
            ],
            firstFavorite: false,
          },
        }
      }
      return state
    }
    case FETCH_FAVORITE_ARTISTS_PENDING: {
      return {
        ...state,
        favoriteArtists: {
          ...state.favoriteArtists,
          isFetching: true,
        },
      }
    }
    case FETCH_FAVORITE_ARTISTS_FULFILLED: {
      const data = {}
      const list = []
      if (action.payload.data) {
        action.payload.data.forEach(artist => {
          data[artist.id] = { ...state.data[artist.id], ...artist }
          list.push(artist.id)
        })
      }
      return {
        ...state,
        favoriteArtists: {
          ...state.favoriteArtists,
          list,
          hasFetched: true,
          isFetching: false,
          firstFavorite: list.length > 0 ? false : state.favoriteArtists.firstFavorite,
        },
        data: {
          ...state.data,
          ...data,
        },
      }
    }
    case FETCH_FAVORITE_ARTISTS_REJECTED: {
      return {
        ...state,
        favoriteArtists: {
          ...state.favoriteArtists,
          hasFetched: true,
          isFetching: false,
        },
      }
    }
    case FETCH_MATCHING_ARTISTS_PENDING: {
      return {
        ...state,
        matchingArtists: {
          ...state.matchingArtists,
          isFetching: true,
        },
      }
    }
    case FETCH_MATCHING_ARTISTS_FULFILLED: {
      const data = {}
      const list = []
      if (action.payload.data) {
        action.payload.data.forEach(row => {
          if (row && row.artist) {
            data[row.artist.id] = { ...state.data[row.artist.id], ...row.artist }
            list.push(row.artist.id)
          }
        })
      }
      return {
        ...state,
        matchingArtists: {
          ...state.matchingArtists,
          list,
          hasFetched: true,
          isFetching: false,
        },
        data: {
          ...state.data,
          ...data,
        },
      }
    }
    case FETCH_MATCHING_ARTISTS_REJECTED: {
      return {
        ...state,
        matchingArtists: {
          ...state.matchingArtists,
          hasFetched: true,
          isFetching: false,
        },
      }
    }
    default: {
      return state
    }
  }
}

export default artistReducer

const artistState = state => state.artist
const artistDataState = state => state.artist.data
const userArtistsState = state => state.artist.userArtists
const statsDataState = state => state.artist.statsData

export const getArtist = createSelector(artistState, state => {
  if (!state.artist.id || !state.data[state.artist.id]) {
    return {}
  }
  return state.data[state.artist.id]
})

export const isLoading = createSelector(artistState, state => state.artist.isFetching)

export const hasLoaded = createSelector(artistState, state => state.artist.hasFetched)

export const isServerFetched = createSelector(artistState, state => state.artist.serverFetched)

export const getUserArtists = createSelector(
  userArtistsState,
  artistDataState,
  (userArtistsstate, artistData) => {
    const artists = []
    if (userArtistsstate.list.length === 0) {
      return artists
    }

    userArtistsstate.list.forEach(artistId => {
      if (artistData[artistId]) {
        artists.push(artistData[artistId])
      }
    })
    return artists
  },
)

export const isUserArtistsLoading = createSelector(userArtistsState, state => state.isFetching)

export const hasUserArtistsLoaded = createSelector(userArtistsState, state => state.hasFetched)

export const getUserArtistsCount = createSelector(userArtistsState, state => {
  if (state.list && state.list.length) {
    return state.list.length
  }
  return 0
})

export const getFrontpageArtists = createSelector(artistState, state => {
  const artists = []
  if (state.frontpageArtists.list.length === 0) {
    return artists
  }

  state.frontpageArtists.list.forEach(artistId => {
    if (state.data[artistId]) {
      artists.push(state.data[artistId])
    }
  })
  return artists
})

export const isFrontpageArtistsLoading = createSelector(
  artistState,
  state => state.frontpageArtists.isFetching,
)
export const isMoreFrontpageArtists = createSelector(
  artistState,
  state => state.frontpageArtists.isMore,
)

export const hasFrontpageArtistsLoaded = createSelector(
  artistState,
  state => state.frontpageArtists.hasFetched,
)

export const getJoinArtists = createSelector(artistState, state => {
  const artists = []
  if (state.joinArtists.list.length === 0) {
    return artists
  }

  state.joinArtists.list.forEach(artistId => {
    if (state.data[artistId]) {
      artists.push(state.data[artistId])
    }
  })
  return artists
})

export const isJoinArtistsLoading = createSelector(
  artistState,
  state => state.joinArtists.isFetching,
)
export const isMoreJoinArtists = createSelector(artistState, state => state.joinArtists.isMore)

export const hasJoinArtistsLoaded = createSelector(
  artistState,
  state => state.joinArtists.hasFetched,
)

export const getFavoriteArtists = createSelector(artistState, state => {
  const artists = []
  if (state.favoriteArtists.list.length === 0) {
    return artists
  }

  state.favoriteArtists.list.forEach(artistId => {
    if (state.data[artistId]) {
      artists.push(state.data[artistId])
    }
  })
  return artists
})
export const getFavoriteArtistIds = createSelector(artistState, state => {
  const artists = {}
  if (state.favoriteArtists.list.length === 0) {
    return artists
  }

  state.favoriteArtists.list.forEach(artistId => {
    if (state.data[artistId]) {
      artists[artistId] = state.data[artistId]
    }
  })
  return artists
})

export const isFavoriteArtistsLoading = createSelector(
  artistState,
  state => state.favoriteArtists.isFetching,
)

export const hasFavoriteArtistsLoaded = createSelector(
  artistState,
  state => state.favoriteArtists.hasFetched,
)

export const isFirstFavorite = createSelector(
  artistState,
  state => state.favoriteArtists.firstFavorite,
)

export const getMatchingArtists = createSelector(artistState, state => {
  const artists = []
  if (state.matchingArtists.list.length === 0) {
    return artists
  }

  state.matchingArtists.list.forEach(artistId => {
    if (state.data[artistId]) {
      artists.push(state.data[artistId])
    }
  })
  return artists
})

export const isMatchingArtistsLoading = createSelector(
  artistState,
  state => state.matchingArtists.isFetching,
)

export const hasMatchingArtistsLoaded = createSelector(
  artistState,
  state => state.matchingArtists.hasFetched,
)

export const getSearchData = createSelector(artistState, state => state.searchData)

export const getSearchArtists = createSelector(artistState, state => {
  const artists = []
  if (state.searchArtists.list.length === 0) {
    return artists
  }

  state.searchArtists.list.forEach(artistId => {
    if (state.data[artistId]) {
      artists.push(state.data[artistId])
    }
  })
  return artists
})

export const getSearchFilters = createSelector(artistState, state => state.searchArtists.filters)

export const isSearchArtistsLoading = createSelector(
  artistState,
  state => state.searchArtists.isFetching,
)
export const isMoreSearchArtists = createSelector(artistState, state => state.searchArtists.isMore)
export const totalCountSearchArtists = createSelector(
  artistState,
  state => state.searchArtists.totalCount,
)

export const hasSearchArtistsLoaded = createSelector(
  artistState,
  state => state.searchArtists.hasFetched,
)

const getPageviewStats = (state, artistId) => {
  const statsState = statsDataState(state)
  if (statsState && statsState[artistId]) {
    return statsState[artistId]
  }
  return {}
}

export const makeGetPageviewLoading = () =>
  createSelector([getPageviewStats], state => state.isFetching || false)

export const makeGetPageviewLoaded = () =>
  createSelector([getPageviewStats], state => state.hasFetched || false)

export const makeGetPageviewData = () =>
  createSelector([getPageviewStats], state => state.data || [])
