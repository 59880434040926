import { createSelector } from 'reselect'

import {
  RELOAD_ARTIST_ADMIN,
  RELOADING_ARTIST_ADMIN,
  RELOAD_ARTIST_SCORE_ADMIN,
  RELOADING_ARTIST_SCORE_ADMIN,
  FETCH_ARTIST_ADMIN_PENDING,
  FETCH_ARTIST_ADMIN_FULFILLED,
  FETCH_ARTIST_ADMIN_REJECTED,
  FETCH_ARTIST_REPERTOIRE_ADMIN_PENDING,
  FETCH_ARTIST_REPERTOIRE_ADMIN_FULFILLED,
  FETCH_ARTIST_REPERTOIRE_ADMIN_REJECTED,
  CHANGE_ARTIST_REPERTOIRE_ADMIN_ORDER,
  SAVE_ARTIST_ADMIN_DATA,
  SAVING_ARTIST_ADMIN_DATA,
  DELETE_ARTIST_ADMIN_DATA,
  SET_ARTIST_ADMIN_FORM_DATA,
  RESET_ARTIST_ADMIN_DATA,
  INIT_ARTIST_ADMIN_FORM_DATA,
  SET_HOME_LOCATION_ADMIN_DATA,
  // SET_HOME_LOCATION_ADMIN_DATA_ERROR,
  UNSET_HOME_LOCATION_ADMIN_DATA,
  // UNSET_HOME_LOCATION_ADMIN_DATA_ERROR,
  ADD_PERFORM_LOCATION_ADMIN_DATA,
  // ADD_PERFORM_LOCATION_ADMIN_DATA_ERROR,
  REMOVE_PERFORM_LOCATION_ADMIN_DATA,
  ADD_REPERTOIRE_SONG_ARTIST_ADMIN_DATA,
  ADD_REPERTOIRE_SONG_ARTIST_ADMIN_DATA_ERROR,
  REMOVE_REPERTOIRE_SONG_ARTIST_ADMIN_DATA,
  PUBLISH_ARTIST_FULFILLED,
  UNPUBLISH_ARTIST_FULFILLED,
} from './ArtistAdminActions'

const defaultContactInfo = {
  bookingEmail: '',
  contactName: '',
  contactEmail: '',
  contactPhone: '',
  contactIsRepresenting: false,
}

const defaultFacts = {
  playTimeMax: '',
  priceMin: '',
  playedSince: '',
  details: {
    acoustic: false,
    electric: false,
    soundSystem: false,
    lightSystem: false,
    pausemusic: false,
  },
}

const defaultInvoiceInfo = {
  invoiceName: '',
  invoiceAddress: '',
  invoiceZip: '',
  invoiceCity: '',
  organizationNumber: '',
}
const getDefaultForm = () => ({
  name: '',
  slogan: '',
  intro: '',
  description: '',
  homeLocation: [],
  performLocations: [],
  eventTypes: [],
  memberCounts: [],
  members: [],
  contactInfo: defaultContactInfo,
  invoiceInfo: defaultInvoiceInfo,
  facts: defaultFacts,
  artistType: '',
  mainCategory: '',
  otherCategories: [],
  genres: [],
  video: [],
  repertoireIntro: '',
  showRepertoireIntro: false,
  errorMessage: [],
})

const getDefaultRepertoire = () => ({
  data: [],
  lastAddedSongId: '',
  lastRemovedSongId: '',
  sortField: '',
  sortDir: 'desc',
  isFetching: false,
  hasFetched: false,
  errorCode: '',
  errorMessage: '',
})
const initialState = {
  data: {},
  isFetching: false,
  hasFetched: false,
  isSaving: false,
  hasSaved: false,
  isUpdating: false,
  hasUpdated: false,
  repertoire: getDefaultRepertoire(),
  form: getDefaultForm(),
}

const artistReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ARTIST_ADMIN_DATA: {
      return { ...state, form: getDefaultForm(), data: {} }
    }
    case INIT_ARTIST_ADMIN_FORM_DATA: {
      return { ...state, form: { ...getDefaultForm(), ...action.form } }
    }
    case SET_ARTIST_ADMIN_FORM_DATA: {
      return { ...state, form: { ...state.form, ...action.form } }
    }
    case DELETE_ARTIST_ADMIN_DATA: {
      return { ...state, data: {}, isFetching: false, hasFetched: false }
    }
    case SAVING_ARTIST_ADMIN_DATA: {
      return { ...state, isSaving: true, hasSaved: false }
    }
    case SAVE_ARTIST_ADMIN_DATA: {
      // I'm doing this to avoid reloading the whole form object if there is an id already
      if (!state.form.id) {
        return {
          ...state,
          data: action.data,
          form: {
            ...state.form,
            id: action.data.id,
          },
          isSaving: false,
          hasSaved: true,
        }
      }
      return {
        ...state,
        data: action.data,
        isSaving: false,
        hasSaved: true,
      }
    }
    case PUBLISH_ARTIST_FULFILLED: {
      return {
        ...state,
        data: action.data,
      }
    }
    case UNPUBLISH_ARTIST_FULFILLED: {
      return {
        ...state,
        data: action.data,
      }
    }
    case FETCH_ARTIST_ADMIN_PENDING: {
      return {
        ...state,
        data: {},
        isFetching: true,
        hasFetched: false,
      }
    }
    case FETCH_ARTIST_ADMIN_FULFILLED: {
      return {
        ...state,
        data: action.payload.data,
        isFetching: false,
        hasFetched: true,
      }
    }
    case RELOADING_ARTIST_ADMIN: {
      return { ...state, isUpdating: true, hasUpdated: false }
    }
    case RELOAD_ARTIST_ADMIN: {
      return {
        ...state,
        data: action.data,
        isUpdating: false,
        hasUpdated: true,
      }
    }
    case RELOAD_ARTIST_SCORE_ADMIN: {
      return {
        ...state,
        data: {
          ...state.data,
          score: action.data.score,
        },
        isUpdating: false,
        hasUpdated: true,
      }
    }
    case FETCH_ARTIST_ADMIN_REJECTED: {
      return {
        ...state,
        data: {},
        isFetching: false,
        hasFetched: true,
      }
    }
    case SET_HOME_LOCATION_ADMIN_DATA: {
      return {
        ...state,
        isSaving: false,
        hasSaved: true,
        data: action.data,
        form: {
          ...state.form,
          homeLocation: action.data.homeLocation,
        },
      }
    }
    case UNSET_HOME_LOCATION_ADMIN_DATA: {
      return {
        ...state,
        isSaving: false,
        hasSaved: true,
        data: action.data,
        form: {
          ...state.form,
          homeLocation: action.data.homeLocation,
        },
      }
    }
    case ADD_PERFORM_LOCATION_ADMIN_DATA: {
      return {
        ...state,
        isSaving: false,
        hasSaved: true,
        data: action.data,
      }
    }
    case REMOVE_PERFORM_LOCATION_ADMIN_DATA: {
      return {
        ...state,
        isSaving: false,
        hasSaved: true,
        data: action.data,
      }
    }
    case ADD_REPERTOIRE_SONG_ARTIST_ADMIN_DATA_ERROR: {
      return {
        ...state,
        isSaving: false,
        hasSaved: true,
        repertoire: {
          ...state.repertoire,
          errorCode: action.errorCode,
          errorMessage: action.errorMessage,
        },
      }
    }
    case ADD_REPERTOIRE_SONG_ARTIST_ADMIN_DATA: {
      return {
        ...state,
        isSaving: false,
        hasSaved: true,
        repertoire: {
          ...state.repertoire,
          lastAddedSongId: action.songId,
          lastRemovedSongId: '',
          errorCode: '',
          errorMessage: '',
        },
      }
    }

    case REMOVE_REPERTOIRE_SONG_ARTIST_ADMIN_DATA: {
      return {
        ...state,
        isSaving: false,
        hasSaved: true,
        repertoire: {
          ...state.repertoire,
          lastAddedSongId: '',
          lastRemovedSongId: action.songId,
          errorCode: '',
          errorMessage: '',
        },
      }
    }
    case CHANGE_ARTIST_REPERTOIRE_ADMIN_ORDER: {
      return {
        ...state,
        repertoire: {
          ...state.repertoire,
          sortField: action.sortField,
          sortDir: action.sortDir,
        },
      }
    }
    case FETCH_ARTIST_REPERTOIRE_ADMIN_PENDING: {
      return {
        ...state,
        repertoire: {
          ...state.repertoire,
          isFetching: true,
          hasFetched: false,
        },
      }
    }

    case FETCH_ARTIST_REPERTOIRE_ADMIN_FULFILLED: {
      return {
        ...state,
        repertoire: {
          ...state.repertoire,
          data: action.payload.data,
          isFetching: false,
          hasFetched: true,
        },
      }
    }

    case FETCH_ARTIST_REPERTOIRE_ADMIN_REJECTED: {
      return {
        ...state,
        repertoire: {
          ...state.repertoire,
          data: [],
          isFetching: false,
          hasFetched: true,
        },
      }
    }

    default: {
      return state
    }
  }
}

export default artistReducer

const artistAdminState = (state) => state.artistAdmin

export const getArtist = createSelector(artistAdminState, (state) => state.data)

export const artistHasFetched = createSelector(artistAdminState, (state) => state.hasFetched)

export const artistIsFetching = createSelector(artistAdminState, (state) => state.isFetching)

export const getArtistForm = createSelector(artistAdminState, (state) => state.form)

export const artistIsSaving = createSelector(artistAdminState, (state) => state.isSaving)
export const artistHasSaved = createSelector(artistAdminState, (state) => state.hasSaved)

export const getArtistRepertoire = createSelector(artistAdminState, (state) =>
  state.repertoire.data.filter((r) => r !== null),
)

export const getArtistRepertoireSortField = createSelector(
  artistAdminState,
  (state) => state.repertoire.sortField,
)

export const getArtistRepertoireSortDir = createSelector(
  artistAdminState,
  (state) => state.repertoire.sortDir,
)

export const getArtistRepertoireErrorMessage = createSelector(
  artistAdminState,
  (state) => state.repertoire.errorMessage,
)

export const getArtistRepertoireLastAddedSongId = createSelector(
  artistAdminState,
  (state) => state.repertoire.lastAddedSongId,
)

export const getArtistRepertoireLastRemovedSongId = createSelector(
  artistAdminState,
  (state) => state.repertoire.lastRemovedSongId,
)
