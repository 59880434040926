import { createSelector } from 'reselect'
import * as types from './OptionsTypes'

const initialState = {
  loaded: false,
  artistMemberTypes: {},
  danceTypes: {},
  instruments: {},
  categories: {},
  eventTypes: {},
  artistTypes: {},
  genres: {},
}

const optionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_OPTIONS_SUCCESS:
      if (!action.data || !action.data.options) {
        return {
          ...initialState,
        }
      }
      return {
        loaded: true,
        ...action.data.options,
      }
    case types.GET_OPTIONS_REJECTED: {
      return {
        ...initialState,
      }
    }
    default: {
      return state
    }
  }
}

export default optionsReducer

const getOptionsState = state => state.options

export const getIsLoadedReducer = state => getOptionsState(state).loaded

export const getCategories = createSelector(getOptionsState, state => state.categories)

export const getInstruments = createSelector(getOptionsState, state => state.instruments)

export const getEventTypes = createSelector(getOptionsState, state => state.eventTypes)

export const getArtistTypes = createSelector(getOptionsState, state => state.artistTypes)

export const getGenres = createSelector(getOptionsState, state => state.genres)

export const getArtistMemberTypes = createSelector(
  getOptionsState,
  state => state.artistMemberTypes,
)

export const getDanceTypes = createSelector(getOptionsState, state => state.danceTypes)
