import { createSelector } from 'reselect'

import {
  FETCH_REPERTOIRE_ARTIST_LOOKUP_PENDING,
  FETCH_REPERTOIRE_ARTIST_LOOKUP_FULFILLED,
  FETCH_REPERTOIRE_SONG_LOOKUP_PENDING,
  FETCH_REPERTOIRE_SONG_LOOKUP_FULFILLED,
  SAVE_REPERTOIRE_ARTIST,
  SAVE_REPERTOIRE_SONG,
} from './RepertoireActions'

const initialState = {
  artists: [],
  artist: {},
  songs: [],
  song: {},
  isFetchingArtists: false,
  hasFetchedArtists: false,
  isFetchingSongs: false,
  hasFetchedSongs: false,
  errorMessage: '',
}

const repertoireReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPERTOIRE_ARTIST_LOOKUP_PENDING: {
      return {
        ...state,
        isFetchingArtists: true,
      }
    }
    case FETCH_REPERTOIRE_ARTIST_LOOKUP_FULFILLED: {
      return {
        ...state,
        artists: action.payload.data,
        artist: {},
        songs: [],
        isFetchingArtists: false,
        hasFetchedArtists: true,
      }
    }
    case FETCH_REPERTOIRE_SONG_LOOKUP_PENDING: {
      return {
        ...state,
        isFetchingSongs: true,
        hasFetchedSongs: false,
      }
    }

    case FETCH_REPERTOIRE_SONG_LOOKUP_FULFILLED: {
      return {
        ...state,
        songs: action.payload.data,
        song: {},
        isFetchingSongs: false,
        hasFetchedSongs: true,
      }
    }

    case SAVE_REPERTOIRE_ARTIST: {
      return {
        ...state,
        artist: action.data,
      }
    }

    case SAVE_REPERTOIRE_SONG: {
      return {
        ...state,
        song: action.data,
      }
    }

    default: {
      return state
    }
  }
}

export default repertoireReducer

const repertoireState = state => state.repertoire

export const getRepertoireArtist = createSelector(repertoireState, state => state.artist)

export const getRepertoireArtists = createSelector(repertoireState, state => state.artists)

export const getRepertoireIsFetchingArtists = createSelector(
  repertoireState,
  state => state.isFetchingArtists,
)

export const getRepertoireHasFetchedArtists = createSelector(
  repertoireState,
  state => state.hasFetchedArtists,
)

export const getRepertoireSong = createSelector(repertoireState, state => state.song)

export const getRepertoireSongs = createSelector(repertoireState, state => state.songs)

export const getRepertoireIsFetchingSongs = createSelector(
  repertoireState,
  state => state.isFetchingSongs,
)

export const getRepertoireHasFetchedSongs = createSelector(
  repertoireState,
  state => state.hasFetchedSongs,
)
