import * as types from './types'
import { getJSON } from '../../libs/data'

export const getMyEventplannerRequestsAction = () => ({ type: types.GET_MY_EVENTPLANNER_REQUESTS })
export const fetchMyEventplannerRequestsPendingAction = () => ({
  type: types.FETCH_MY_EVENTPLANNER_REQUESTS_PENDING,
})
export const fetchMyEventplannerRequestsErrorAction = error => ({
  type: types.FETCH_MY_EVENTPLANNER_REQUESTS_ERROR,
  error,
})
export const fetchMyEventplannerRequestsSuccessAction = data => ({
  type: types.FETCH_MY_EVENTPLANNER_REQUESTS_SUCCESS,
  payload: { data },
})

export const reloadOneEventplannerRequestAction = gigRequestId => ({
  type: types.GET_ONE_EVENTPLANNER_REQUEST,
  gigRequestId,
})
export const getOneEventplannerRequestAction = gigRequestId => ({
  type: types.GET_ONE_EVENTPLANNER_REQUEST,
  gigRequestId,
})
export const fetchOneEventplannerRequestPendingAction = gigRequestId => ({
  type: types.FETCH_ONE_EVENTPLANNER_REQUEST_PENDING,
  payload: { gigRequestId },
})
export const fetchOneEventplannerRequestErrorAction = error => ({
  type: types.FETCH_ONE_EVENTPLANNER_REQUEST_ERROR,
  error,
})
export const fetchOneEventplannerRequestSuccessAction = (gigRequestId, data) => ({
  type: types.FETCH_ONE_EVENTPLANNER_REQUEST_SUCCESS,
  payload: {
    data,
    gigRequestId,
  },
})

export const reloadOneEventplannerRequestForArtistAction = (gigRequestId, artistId) => ({
  type: types.RELOAD_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST,
  gigRequestId,
  artistId,
})
export const reloadOneEventplannerRequestForArtistPendingAction = (gigRequestId, artistId) => ({
  type: types.RELOAD_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST_PENDING,
  payload: { gigRequestId, artistId },
})

export const getOneEventplannerRequestForArtistAction = (gigRequestId, artistId) => ({
  type: types.GET_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST,
  gigRequestId,
  artistId,
})
export const fetchOneEventplannerRequestForArtistPendingAction = (gigRequestId, artistId) => ({
  type: types.FETCH_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST_PENDING,
  payload: { gigRequestId, artistId },
})
export const fetchOneEventplannerRequestForArtistErrorAction = error => ({
  type: types.FETCH_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST_ERROR,
  error,
})
export const fetchOneEventplannerRequestForArtistSuccessAction = (
  gigRequestId,
  artistId,
  data,
) => ({
  type: types.FETCH_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST_SUCCESS,
  payload: {
    data,
    gigRequestId,
    artistId,
  },
})

export const getMyEventplannerRequestsCountAction = () => dispatch => {
  getJSON('/api/v1/gigRequest/myRequestCount?status=ACTIVE').then(
    data => {
      dispatch({
        type: types.FETCH_MY_EVENTPLANNER_REQUESTS_COUNT_SUCCESS,
        payload: { requestCount: data.requestCount },
      })
    },
    () => {},
  )
}
export const fetchMyEventplannerRequestsCountSuccessAction = requestCount => ({
  type: types.FETCH_MY_EVENTPLANNER_REQUESTS_COUNT_SUCCESS,
  payload: { requestCount },
})

export const sendMessageAction = (gigRequestId, artistId, message, date) => ({
  type: types.SEND_MESSAGE,
  gigRequestId,
  artistId,
  message,
  date,
})

export const getQuoteAction = quoteId => ({
  type: types.GET_QUOTE,
  quoteId,
})
export const fetchQuotePendingAction = () => ({
  type: types.FETCH_QUOTE_PENDING,
})
export const fetchQuoteErrorAction = error => ({
  type: types.FETCH_QUOTE_ERROR,
  error,
})
export const fetchQuoteSuccessAction = (quoteId, data) => ({
  type: types.FETCH_QUOTE_SUCCESS,
  payload: { quoteId, data },
})

export const resetUnreadCountAction = (gigRequestId, artistId) => ({
  type: types.RESET_UNREAD_COUNT,
  gigRequestId,
  artistId,
})

export const updateUnreadCountAction = () => ({
  type: types.UPDATE_UNREAD_COUNT,
})
