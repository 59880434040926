import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router'
import { useLocation, useHistory, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import loadable from '@loadable/component'
import queryString from 'query-string'

import { CurrentUserShape } from '../models/CurrentUser'

import { initOptionsAction, initOptionsIfNeededAction } from '../stores/Options/OptionsActions'
import { fetchMe } from '../stores/CurrentUser/CurrentUserActions'
import {
  getCounties as getCountiesAction,
  getCountiesIfNeededAction,
} from '../stores/Locations/LocationsActions'
import {
  fetchUserArtists,
  fetchFavoriteArtistsAction,
  fetchFavoriteArtistsIfNeededAction,
} from '../stores/Artist/ArtistActions'
import {
  getMyEventplannerRequestsAction,
  getMyEventplannerRequestsCountAction,
  updateUnreadCountAction as updateUnreadCountForEventplannerAction,
} from '../stores/gig-request-eventplanner/actions'
import { getRequestCount as getRequestCountForEventplanner } from '../stores/gig-request-eventplanner/reducer'

import {
  getMyArtistRequestsAction,
  getMyArtistRequestsCountAction,
  updateUnreadCountAction as updateUnreadCountForArtistAction,
} from '../stores/GigRequestArtist/GigRequestArtistActions'
import { getRequestCount as getRequestCountForArtist } from '../stores/GigRequestArtist/GigRequestArtistReducer'

import { getCurrentUser as getCurrentUserSelector } from '../stores/CurrentUser/CurrentUserReducer'
import useInterval from '../hooks/use-interval'

import ErrorBoundary from '../components/ErrorBoundry'
import ScrollManager from '../components/ScrollManager'

/* ROUTES */

import GlobalStyle from '../components/global-style'
import CheckUserStatus from '../containers/check-user-status'
import { trackPageView } from '../libs/analytics'

// import CookieWarning from '../containers/cookie-warning'
// import { getCookiesFromRequestAction } from '../stores/cookies/actions'

const FrontPageRoute = loadable(() => import(/* webpackChunkName: "frontpage" */ './frontpage'))

const FavoritePageRoute = loadable(() =>
  import(/* webpackChunkName: "favorite-page" */ './favorite-page'),
)

const ArticlesPageRoute = loadable(() => import(/* webpackChunkName: "article" */ './Articles'))

const ProfileDetailsRoute = loadable(() =>
  import(/* webpackChunkName: "profile-details" */ './profile-details'),
)

const JoinRoute = loadable(() => import('./join'))
const HelpRoute = loadable(() => import('./help'))

const SearchRoute = loadable(() => import('./search'))

const ControlpanelRoute = loadable(() => import('./controlpanel'))

const EventplannerRoute = loadable(() => import('./eventplanner'))

const LoginRoute = loadable(() => import('./login'))

const RegisterRoute = loadable(() => import('./register'))

const NoUserRoute = loadable(() => import('./no-user'))

const VerifyEmailSuccessRoute = loadable(() => import('./verify-email-success'))

const VerifyEmailErrorRoute = loadable(() => import('./verify-email-error'))

const PasswordResetSuccessRoute = loadable(() => import('./password-reset-success'))

const PasswordResetErrorRoute = loadable(() => import('./password-reset-error'))

const PrintRoute = loadable(() => import('./print'))

const UserProfileRoute = loadable(() => import('./user-profile'))

const CMSContentRoute = loadable(() => import('./cms-content'))

const WriteReviewRoute = loadable(() => import('./WriteReview'))

const mapStateToProps = (state) => ({
  currentUser: getCurrentUserSelector(state),
  artistRequestCount: getRequestCountForArtist(state),
  eventplannerRequestCount: getRequestCountForEventplanner(state),
})

const mapDispatchToProps = (dispatch) => ({
  updateUnreadCountForArtist: () => {
    dispatch(updateUnreadCountForArtistAction())
  },
  updateUnreadCountForEventplanner: () => {
    dispatch(updateUnreadCountForEventplannerAction())
  },
  getInitData: () => {
    dispatch(initOptionsIfNeededAction())
  },
  getCurrentUser: () => {
    dispatch(fetchMe())
  },
  getUserArtists: () => {
    dispatch(fetchUserArtists())
  },
  getFavoriteArtists: () => {
    dispatch(fetchFavoriteArtistsIfNeededAction())
  },
  getCounties: () => {
    dispatch(getCountiesIfNeededAction())
  },
  getMyRequests: () => {
    dispatch(getMyEventplannerRequestsCountAction())
    dispatch(getMyEventplannerRequestsAction())
    dispatch(getMyArtistRequestsAction())
    dispatch(getMyArtistRequestsCountAction())
  },
})

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background: #fff;
  max-width: 100%;
`

const getRedirectUrl = (search) => {
  let url = '/'
  const query = queryString.parse(search)
  if (query && query.url) {
    url = query.url
  }
  return url
}

const Routes = React.memo(
  ({
    getInitData,
    getCurrentUser,
    getUserArtists,
    getFavoriteArtists,
    getCounties,
    currentUser,
    getMyRequests,
    updateUnreadCountForArtist,
    updateUnreadCountForEventplanner,
    artistRequestCount,
    eventplannerRequestCount,
  }) => {
    const location = useLocation()

    React.useEffect(() => {
      setTimeout(() => {
        trackPageView(`${location.pathname}${location.search}${location.hash}`)
      }, 0)
    }, [location])

    React.useEffect(() => {
      getInitData()
      getFavoriteArtists()
      getCounties()
      if ({}.hasOwnProperty.call(currentUser, 'id')) {
        getCurrentUser()
        updateUnreadCountForArtist()
        updateUnreadCountForEventplanner()
        getUserArtists()
        getMyRequests()
      }
    }, [])

    useInterval(() => {
      if ({}.hasOwnProperty.call(currentUser, 'id')) {
        if (artistRequestCount > 0) {
          updateUnreadCountForArtist()
        }
        if (eventplannerRequestCount > 0) {
          updateUnreadCountForEventplanner()
        }
      }
    }, 30000)

    return (
      <ErrorBoundary>
        <ScrollManager>
          <CheckUserStatus>
            <Body>
              <Switch>
                <Route exact path="/" component={FrontPageRoute} />
                <Route path="/favorites" component={FavoritePageRoute} />
                <Route path="/articles" component={ArticlesPageRoute} />
                <Route path="/print" component={PrintRoute} />
                <Route path="/artist/:artistSlug" component={ProfileDetailsRoute} />
                <Route path="/band/:artistSlug" component={ProfileDetailsRoute} />
                <Route path="/solo/:artistSlug" component={ProfileDetailsRoute} />
                <Route path="/controlpanel" component={ControlpanelRoute} />
                <Route path="/eventplanner" component={EventplannerRoute} />
                <Route path="/join" component={JoinRoute} />
                <Route path="/help" component={HelpRoute} />

                <Route path="/s" component={SearchRoute} />
                <Route path="/verify-email-success" component={VerifyEmailSuccessRoute} />
                <Route path="/verify-email-error" component={VerifyEmailErrorRoute} />
                <Route path="/password-reset-success" component={PasswordResetSuccessRoute} />
                <Route path="/password-reset-error" component={PasswordResetErrorRoute} />
                <Route path="/user-profile" component={UserProfileRoute} />
                <Route path="/no-user" component={NoUserRoute} />
                <Route path="/write-review/:gigId" component={WriteReviewRoute} />
                <Route
                  path="/login"
                  render={() => {
                    if (!{}.hasOwnProperty.call(currentUser, 'id')) {
                      return <LoginRoute />
                    }
                    const redirectUrl = getRedirectUrl(location.search)
                    return <Redirect to={redirectUrl} />
                  }}
                />
                <Route
                  path="/register"
                  render={() => {
                    if (!{}.hasOwnProperty.call(currentUser, 'id')) {
                      return <RegisterRoute />
                    }
                    const redirectUrl = getRedirectUrl(location.search)
                    return <Redirect to={redirectUrl} />
                  }}
                />
                {/* All routes goes here. Check for page not found here */}
                <Route path="/:slug" component={CMSContentRoute} />
              </Switch>
            </Body>
          </CheckUserStatus>
        </ScrollManager>
        {/* <CookieWarning /> */}
        <GlobalStyle />
      </ErrorBoundary>
    )
  },
)

Routes.propTypes = {
  updateUnreadCountForArtist: PropTypes.func.isRequired,
  updateUnreadCountForEventplanner: PropTypes.func.isRequired,
  getInitData: PropTypes.func.isRequired,
  currentUser: CurrentUserShape,
  getMyRequests: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  getUserArtists: PropTypes.func.isRequired,
  getFavoriteArtists: PropTypes.func.isRequired,
  getCounties: PropTypes.func.isRequired,
  artistRequestCount: PropTypes.number.isRequired,
  eventplannerRequestCount: PropTypes.number.isRequired,
}

Routes.defaultProps = {
  currentUser: null,
}

Routes.dispatchActions = (req, dispatch) => [
  dispatch(initOptionsAction(req)),
  dispatch(fetchMe(req)),
  dispatch(fetchUserArtists(req)),
  dispatch(fetchFavoriteArtistsAction(req)),
  dispatch(getCountiesAction(req)),
  // dispatch(getMyEventplannerRequestsAction(req)), // We need to enable this when we got sagas to work on server
  // dispatch(getMyArtistRequestsAction(req)), // We need to enable this when we got sagas to work on server
  // dispatch(getCookiesFromRequestAction(req)),
]

export default compose(connect(mapStateToProps, mapDispatchToProps))(Routes)
