import { combineReducers } from 'redux'

import currentUserReducer from './CurrentUser/CurrentUserReducer'
import optionsReducer from './Options/OptionsReducer'
import artistReducer from './Artist/ArtistReducer'
import artistAdminReducer from './ArtistAdmin/ArtistAdminReducer'
import locationsReducer from './Locations/LocationsReducer'
import repertoireReducer from './Repertoire/RepertoireReducer'
import gigRequestReducer from './GigRequest/GigRequestReducer'
import gigRequestArtistReducer from './GigRequestArtist/GigRequestArtistReducer'
import gigRequestEventplannerReducer from './gig-request-eventplanner/reducer'
import authReducer from './Auth/AuthReducer'
import cookiesReducer from './cookies/reducer'
import cmsContentReducer from './cms-content/reducer'

export default combineReducers({
  currentUser: currentUserReducer,
  options: optionsReducer,
  artist: artistReducer,
  artistAdmin: artistAdminReducer,
  locations: locationsReducer,
  repertoire: repertoireReducer,
  gigRequest: gigRequestReducer,
  gigRequestArtist: gigRequestArtistReducer,
  gigRequestEventplanner: gigRequestEventplannerReducer,
  auth: authReducer,
  cookies: cookiesReducer,
  cmsContent: cmsContentReducer,
})
