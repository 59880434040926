export const FETCH_ALL_BLOG_CONTENT_PENDING = 'CMS_CONTENT/FETCH_ALL_BLOG_PENDING'
export const FETCH_ALL_BLOG_CONTENT_FULFILLED = 'CMS_CONTENT/FETCH_ALL_BLOG_FULFILLED'
export const FETCH_ALL_BLOG_CONTENT_REJECTED = 'CMS_CONTENT/FETCH_ALL_BLOG_REJECTED'

export const FETCH_SYSTEM_TEXT_PENDING = 'CMS_CONTENT/FETCH_SYSTEM_TEXT_PENDING'
export const FETCH_SYSTEM_TEXT_FULFILLED = 'CMS_CONTENT/FETCH_SYSTEM_TEXT_FULFILLED'
export const FETCH_SYSTEM_TEXT_REJECTED = 'CMS_CONTENT/FETCH_SYSTEM_TEXT_REJECTED'

export const FETCH_ONE_ARTICLE_PENDING = 'CMS_CONTENT/FETCH_ONE_ARTICLE_PENDING'
export const FETCH_ONE_ARTICLE_FULFILLED = 'CMS_CONTENT/FETCH_ONE_ARTICLE_FULFILLED'
export const FETCH_ONE_ARTICLE_REJECTED = 'CMS_CONTENT/FETCH_ONE_ARTICLE_REJECTED'

export const FETCH_ARTICLES_PENDING = 'CMS_CONTENT/FETCH_ARTICLES_PENDING'
export const FETCH_ARTICLES_FULFILLED = 'CMS_CONTENT/FETCH_ARTICLES_FULFILLED'
export const FETCH_ARTICLES_REJECTED = 'CMS_CONTENT/FETCH_ARTICLES_REJECTED'

export const FETCH_LANDING_PAGE_PENDING = 'CMS_CONTENT/FETCH_LANDING_PAGE_PENDING'
export const FETCH_LANDING_PAGE_FULFILLED = 'CMS_CONTENT/FETCH_LANDING_PAGE_FULFILLED'
export const FETCH_LANDING_PAGE_REJECTED = 'CMS_CONTENT/FETCH_LANDING_PAGE_REJECTED'
