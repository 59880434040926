export const GET_MY_EVENTPLANNER_REQUESTS = 'GIGREQUEST_EVENTPLANNER/GET_MY_REQUESTS'
export const FETCH_MY_EVENTPLANNER_REQUESTS_PENDING =
  'GIGREQUEST_EVENTPLANNER/FETCH_MY_REQUESTS_PENDING'
export const FETCH_MY_EVENTPLANNER_REQUESTS_SUCCESS =
  'GIGREQUEST_EVENTPLANNER/FETCH_MY_REQUESTS_SUCCESS'
export const FETCH_MY_EVENTPLANNER_REQUESTS_ERROR =
  'GIGREQUEST_EVENTPLANNER/FETCH_MY_REQUESTS_ERROR'

export const GET_ONE_EVENTPLANNER_REQUEST = 'GIGREQUEST_EVENTPLANNER/GET_ONE_REQUEST'
export const FETCH_ONE_EVENTPLANNER_REQUEST_PENDING =
  'GIGREQUEST_EVENTPLANNER/FETCH_ONE_REQUEST_PENDING'
export const FETCH_ONE_EVENTPLANNER_REQUEST_SUCCESS =
  'GIGREQUEST_EVENTPLANNER/FETCH_ONE_REQUEST_SUCCESS'
export const FETCH_ONE_EVENTPLANNER_REQUEST_ERROR =
  'GIGREQUEST_EVENTPLANNER/FETCH_ONE_REQUEST_ERROR'

export const RELOAD_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST =
  'GIGREQUEST_EVENTPLANNER/RELOAD_ONE_REQUEST_FOR_ARTIST'
export const RELOAD_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST_PENDING =
  'GIGREQUEST_EVENTPLANNER/RELOAD_ONE_REQUEST_FOR_ARTIST_PENDING'

export const GET_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST =
  'GIGREQUEST_EVENTPLANNER/GET_ONE_REQUEST_FOR_ARTIST'
export const FETCH_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST_PENDING =
  'GIGREQUEST_EVENTPLANNER/FETCH_ONE_REQUEST_FOR_ARTIST_PENDING'
export const FETCH_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST_SUCCESS =
  'GIGREQUEST_EVENTPLANNER/FETCH_ONE_REQUEST_FOR_ARTIST_SUCCESS'
export const FETCH_ONE_EVENTPLANNER_REQUEST_FOR_ARTIST_ERROR =
  'GIGREQUEST_EVENTPLANNER/FETCH_ONE_REQUEST_FOR_ARTIST_ERROR'

export const FETCH_MY_EVENTPLANNER_REQUESTS_COUNT_SUCCESS =
  'GIGREQUEST_EVENTPLANNER/FETCH_MY_REQUEST_COUNT_SUCCESS'

export const SEND_MESSAGE = 'GIGREQUEST_EVENTPLANNER/SEND_MESSAGE'

export const GET_QUOTE = 'GIGREQUEST_EVENTPLANNER/GET_ONE_QUOTE'
export const FETCH_QUOTE_PENDING = 'GIGREQUEST_EVENTPLANNER/FETCH_QUOTE_PENDING'
export const FETCH_QUOTE_SUCCESS = 'GIGREQUEST_EVENTPLANNER/FETCH_QUOTE_SUCCESS'
export const FETCH_QUOTE_ERROR = 'GIGREQUEST_EVENTPLANNER/FETCH_QUOTE_ERROR'

export const RESET_UNREAD_COUNT = 'GIGREQUEST_EVENTPLANNER/RESET_UNREAD_COUNT'
export const UPDATE_UNREAD_COUNT = 'GIGREQUEST_EVENTPLANNER/UPDATE_UNREAD_COUNT'
export const UPDATE_UNREAD_COUNT_SUCCESS = 'GIGREQUEST_EVENTPLANNER/UPDATE_UNREAD_COUNT_SUCCESS'
