/* eslint-disable ts-styled-plugin */
import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'

import gigplanetTheme from '../../styles/gigplanetTheme'

import dayPickerStyles from './vendors/react-day-picker'
import draftJSStyles from './vendors/draft-js'
import reactImageCropStyles from './vendors/react-image-crop'

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  ${dayPickerStyles}
  ${draftJSStyles}
  ${reactImageCropStyles}

  html {
    box-sizing: border-box;
    height: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  
  img {
    vertical-align: top;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: 'Halcom', Arial, sans-serif;
    text-rendering: optimizeLegibility;
  }
  
  body {
    color: ${gigplanetTheme.element.default.text};
    font-family: 'Halcom', Arial, sans-serif;
    font-style: normal;
    margin: 0;
    min-height: 100%;
  }
  
  [hidden] {
    display: none;
  }
  
  @media print {
    html, body {
      width: 210mm;
      height: 297mm;
      height: 99%;
      min-height: 99%;
    }

    a {
      page-break-inside: avoid
    }
    
    blockquote {
      page-break-inside: avoid;
    }
    h1, h2, h3, h4, h5, h6 { 
      page-break-after: avoid; 
     page-break-inside: avoid;
    }
    
    img { 
      page-break-inside: avoid; 
      page-break-after: avoid; 
    }
  
    table, pre { 
      page-break-inside: avoid;
    }
    
    ul, ol, dl  { 
      page-break-before: avoid;
    }

    a {
      page-break-inside: avoid
    }

    a[href^=http]:after {
      content: " (" attr(href) ")";
    }

    a:after > img {
      content: "";
    }

    article a[href^="#"]:after {
      content: "";
    }

    a:not(:local-link):after {
      content: " (" attr(href) ")";
    }
  }
  
  @page {
    size: A4;
    margin-top: 2cm;
    margin-bottom: 2cm;
    margin-left: 2cm;
    margin-right: 2cm;
  }
`

export default GlobalStyle
