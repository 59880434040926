/* globals window */
import { call, put, takeEvery, all, select } from 'redux-saga/effects'

import { postJSON } from '../../libs/data'

import * as types from './AuthTypes'
import * as actions from './AuthActions'
import * as selectors from './AuthReducer'

function* createUser(action) {
  const url = '/api/v1/auth/register'
  const formState = yield select(selectors.getRegisterForm)
  const form = { ...formState }
  if (action.successUrl) {
    form.url = action.successUrl
  }
  yield put(actions.createUserPendingAction())
  try {
    yield call(postJSON, url, form)
    yield put(actions.createUserSuccessAction())
  } catch (error) {
    const errorMessages = []
    const message = error.response && error.response.message ? error.response.message : null
    if (message && message.error === 'E_VALIDATION') {
      if (message.invalidAttributes) {
        if (message.invalidAttributes.email) {
          errorMessages.push({
            id: 'email',
            message: 'Det finnes en bruker med den e-postadressen fra før',
          })
        }
      }
    } else if (message === 'user-emailalreadyexists') {
      errorMessages.push({
        id: 'exists',
        message:
          'Du forsøker å registrere en bruker med en e-post som allerede finnes i våre systemer.',
      })
    } else {
      errorMessages.push({
        id: 'other',
        message: 'Det har oppstått en feil. Prøv igjen.',
      })
    }
    yield put(actions.createUserErrorAction(errorMessages))
  }
}

function* login(action) {
  const url = '/api/v1/auth/login'
  const formState = yield select(selectors.getLoginForm)
  const form = { ...formState }
  if (action.successUrl) {
    form.url = action.successUrl
  }
  yield put(actions.loginPendingAction())
  try {
    const result = yield call(postJSON, url, form)
    yield put(actions.loginSuccessAction())
    if (result.success) {
      if (window.location.pathname === result.redirectUrl || result.redirectUrl.indexOf('#') > 0) {
        window.location.href = result.redirectUrl
        window.location.reload()
      } else {
        window.location.href = result.redirectUrl
      }
    }
  } catch (error) {
    let verifyError = false
    const errorMessages = []
    const message = error.response && error.response.message ? error.response.message : null
    if (message === 'user-invalidusernamepassword') {
      errorMessages.push({
        id: 'other',
        message: 'Brukernavn eller passord er feil.',
      })
    }
    if (message === 'user-emailunverified') {
      verifyError = true
    }
    yield put(actions.loginErrorAction(errorMessages, verifyError))
  }
}

function* sendForgotPassword() {
  const url = '/api/v1/auth/sendResetPassword'
  const form = yield select(selectors.getForgotPasswordForm)
  yield put(actions.sendForgotPasswordPendingAction())
  try {
    yield call(postJSON, url, form)
    yield put(actions.sendForgotPasswordSuccessAction())
  } catch (error) {
    const errorMessages = []
    const message = error.response && error.response.message ? error.response.message : null
    if (message === 'user-notfound') {
      errorMessages.push({
        id: 'email',
        message: 'Fant ingen bruker med den e-post.',
      })
    }
    yield put(actions.sendForgotPasswordErrorAction(errorMessages))
  }
}

function* newPassword() {
  const url = '/api/v1/auth/updatePassword'
  const form = yield select(selectors.getNewPasswordForm)
  yield put(actions.newPasswordPendingAction())
  try {
    yield call(postJSON, url, form)
    yield put(actions.newPasswordSuccessAction())
  } catch (error) {
    const errorMessages = []
    const message = error.response && error.response.message ? error.response.message : null
    if (message === 'user-notfound') {
      errorMessages.push({
        id: 'password',
        message: 'Ugyldig passord.',
      })
    } else {
      errorMessages.push({
        id: 'other',
        message: 'Noe feilet',
      })
    }
    yield put(actions.newPasswordErrorAction(errorMessages))
  }
}

function* sendVerifyEmail(action) {
  const url = '/api/v1/auth/sendVerifyEmail'
  const formState = yield select(selectors.getVerifyEmailForm)
  const form = { ...formState }
  if (action.successUrl) {
    form.url = action.successUrl
  }
  yield put(actions.sendVerifyEmailPendingAction())
  try {
    yield call(postJSON, url, form)
    yield put(actions.sendVerifyEmailSuccessAction())
  } catch (error) {
    const errorMessages = []
    const message = error.response && error.response.message ? error.response.message : null
    if (message === 'user-notfound') {
      errorMessages.push({
        id: 'email',
        message: 'Fant ingen bruker med den e-post.',
      })
    }
    if (message === 'user-alreadyverified') {
      errorMessages.push({
        id: 'email',
        message: 'E-posten er allerede verifisert.',
      })
    }
    yield put(actions.sendVerifyEmailErrorAction(errorMessages))
  }
}

export default function* usersSagas() {
  yield all([
    takeEvery(types.CREATE_USER, createUser),
    takeEvery(types.LOGIN_USER, login),
    takeEvery(types.FORGOT_PASSWORD, sendForgotPassword),
    takeEvery(types.NEW_PASSWORD, newPassword),
    takeEvery(types.VERIFY_EMAIL, sendVerifyEmail),
  ])
}
