import * as types from './GigRequestArtistTypes'

export const getMyArtistRequestsAction = () => ({ type: types.GET_MY_ARTIST_REQUESTS })
export const fetchMyArtistRequestsPendingAction = () => ({
  type: types.FETCH_MY_ARTIST_REQUESTS_PENDING,
})
export const fetchMyArtistRequestsErrorAction = error => ({
  type: types.FETCH_MY_ARTIST_REQUESTS_ERROR,
  error,
})
export const fetchMyArtistRequestsSuccessAction = data => ({
  type: types.FETCH_MY_ARTIST_REQUESTS_SUCCESS,
  payload: { data },
})

export const getOneArtistRequestAction = (gigRequestId, artistId) => ({
  type: types.GET_ONE_ARTIST_REQUEST,
  gigRequestId,
  artistId,
})

export const resetUnreadCountAction = (gigRequestId, artistId) => ({
  type: types.RESET_UNREAD_COUNT,
  gigRequestId,
  artistId,
})

export const updateUnreadCountAction = () => ({
  type: types.UPDATE_UNREAD_COUNT,
})

export const fetchOneArtistRequestPendingAction = (gigRequestId, artistId) => ({
  type: types.FETCH_ONE_ARTIST_REQUEST_PENDING,
  payload: { gigRequestId, artistId },
})
export const fetchOneArtistRequestErrorAction = error => ({
  type: types.FETCH_ONE_ARTIST_REQUEST_ERROR,
  error,
})
export const fetchOneArtistRequestSuccessAction = (gigRequestId, artistId, data) => ({
  type: types.FETCH_ONE_ARTIST_REQUEST_SUCCESS,
  payload: {
    data,
    gigRequestId,
    artistId,
  },
})

export const reloadOneArtistRequestAction = (gigRequestId, artistId) => ({
  type: types.RELOAD_ONE_ARTIST_REQUEST,
  gigRequestId,
  artistId,
})
export const reloadOneArtistRequestPendingAction = (gigRequestId, artistId) => ({
  type: types.RELOAD_ONE_ARTIST_REQUEST_PENDING,
  payload: { gigRequestId, artistId },
})

export const getMyArtistRequestsCountAction = () => ({ type: types.GET_MY_ARTIST_REQUESTS_COUNT })
export const fetchMyArtistRequestsCountPendingAction = () => ({
  type: types.FETCH_MY_ARTIST_REQUESTS_COUNT_PENDING,
})
export const fetchMyArtistRequestsCountErrorAction = error => ({
  type: types.FETCH_MY_ARTIST_REQUESTS_COUNT_ERROR,
  error,
})
export const fetchMyArtistRequestsCountSuccessAction = requestCount => ({
  type: types.FETCH_MY_ARTIST_REQUESTS_COUNT_SUCCESS,
  payload: { requestCount },
})

export const sendMessageAction = (gigRequestId, artistId, message, date) => ({
  type: types.SEND_MESSAGE,
  gigRequestId,
  artistId,
  message,
  date,
})
