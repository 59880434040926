import { createSelector } from 'reselect'

import { includedOptionsInGigRequestForm, GIGREQUEST_NOT_SET_ID } from '../../models/GigRequest'
import * as types from './GigRequestTypes'

const initialState = {
  form: {
    artistId: '',
    gigRequestId: GIGREQUEST_NOT_SET_ID,
    event: {
      eventType: {
        eventType: '',
        sub: '',
      },
      audienceCount: '',
      venue: '',
      city: '',
      startTime: '',
      endTime: '',
      notes: '',
      included: [...includedOptionsInGigRequestForm],
    },
    requester: {
      organization: '',
      phone: '',
      email: '',
    },
  },
}

const resetState = {
  form: {
    artistId: '',
  },
}

const gigRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_GIGREQUEST_FORM: {
      return {
        ...state,
        form: {
          ...state.form,
          ...resetState.form,
        },
      }
    }
    case types.SET_GIGREQUEST_FORM: {
      return {
        ...state,
        form: {
          ...state.form,
          details: {
            ...state.form.details,
            ...action.data.details,
          },
          requester: {
            ...state.form.requester,
            ...action.data.requester,
          },
        },
      }
    }
    case types.SET_FORM_REQUEST_ID: {
      return {
        ...state,
        form: {
          ...state.form,
          gigRequestId: action.data.gigRequestId,
        },
      }
    }
    case types.SET_FORM_REQUESTER: {
      return {
        ...state,
        form: {
          ...state.form,
          requester: {
            ...state.form.requester,
            ...action.data.requesterData,
          },
        },
      }
    }

    case types.SET_FORM_EVENT: {
      return {
        ...state,
        form: {
          ...state.form,
          event: {
            ...state.form.event,
            ...action.data.eventData,
          },
        },
      }
    }

    case types.SET_FORM_ARTIST: {
      return {
        form: {
          ...state.form,
          artistId: action.data.artistId,
        },
      }
    }
    default: {
      return state
    }
  }
}

export default gigRequestReducer

const getGigRequestState = state => state.gigRequest
const getArtistState = state => state.artist

export const getGigRequestId = createSelector(getGigRequestState, state => state.form.gigRequestId)

export const getEventFormData = createSelector(getGigRequestState, state => state.form.event)

export const getRequesterFormData = createSelector(
  getGigRequestState,
  state => state.form.requester,
)

export const getForm = createSelector(getGigRequestState, state => state.form)

export const getArtistFormData = createSelector(
  [getGigRequestState, getArtistState],
  (gigRequestState, artistState) => {
    if (
      artistState.data &&
      gigRequestState.form.artistId &&
      artistState.data[gigRequestState.form.artistId]
    ) {
      return artistState.data[gigRequestState.form.artistId]
    }
    return null
  },
)
