import * as React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const GigplanetLogo = React.memo(({ alt, inverted }) => (
  <svg
    role="presentation"
    alt={alt}
    width="960"
    height="260"
    viewBox="0 0 960 260"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M177.4,11.41V220.72c0,7.25-5.19,12.31-12.63,12.31s-12.62-5.06-12.62-12.31V72.6c0-16.72-12-28.4-29.22-28.4S93.72,55.88,93.72,72.6V174.39c0,7.25-5.2,12.32-12.63,12.32s-12.63-5.07-12.63-12.32V118.85c0-16.72-12-28.4-29.22-28.4S10,102.13,10,118.85v48.56H26.62v-49c0-7.25,5.19-12.38,12.62-12.38s12.63,5.13,12.63,12.38v55.47h0v.06h0c0,16.68,12,28.33,29.22,28.33s29.18-11.65,29.21-28.33h0V72.15c0-7.25,5.19-12.39,12.63-12.39s12.63,5.14,12.63,12.39v148c0,16.72,12,28.4,29.21,28.4S194,236.91,194,220.19h0V11.41Z"
    />
    <path
      fill="currentColor"
      d="M548.11,104.47c-9.24,0-17.32,5.6-22.22,10.54v-8.73H509.17v108.1h16.72V180.11c3.1,3,12.66,6.94,20.39,6.94,23.34,0,35.83-16.66,35.83-41.44C582.11,119.85,570.66,104.47,548.11,104.47Zm-5.62,67.21c-6.49,0-13.16-2.59-16.6-6.06v-36.3s8-9.78,18-9.78c13.28,0,21.54,10,21.54,26.07C565.39,163.16,556.83,171.68,542.49,171.68Z"
    />
    <rect fill="currentColor" x="377.8" y="106.28" width="16.73" height="78.95" />
    <rect fill="currentColor" x="600.33" y="65.35" width="16.73" height="119.88" />
    <path
      fill="currentColor"
      d="M853,104.47c-23.91,0-38.19,15.32-38.19,41,0,38.31,30.39,41.28,39.7,41.28,10.93,0,20-1.94,26.32-5.63l.7-.41v-16L879.43,166c-6,3.47-14.76,5.55-23.53,5.55-8.16,0-21.9-2.69-24-20.39h56.44V149C888.36,118.19,877.46,104.47,853,104.47ZM832.15,137c1.58-14.74,12.3-17.9,21.19-17.9,11.62,0,17.11,5.21,18.68,17.9Z"
    />
    <path
      fill="currentColor"
      d="M948.15,169.91a61.68,61.68,0,0,1-11.83,1.32c-7.22,0-9.91-3-9.91-11.12V121.58h21.64v-15.3H926.41V84.5H909.68v21.78H897.34v15.3h12.34v39.58c0,16.36,8.72,25.74,23.92,25.74,4.89,0,11.52-.54,15.46-2.06l.91-.35V169.37Z"
    />
    <path
      fill="currentColor"
      d="M386.16,71.1c-7.68,0-11.83,3.39-11.83,11s4.15,11,11.83,11S398,89.76,398,82.12,393.84,71.1,386.16,71.1Z"
    />
    <path
      fill="currentColor"
      d="M307.57,140.15h30.54v5.57c0,14.23-11.8,24.56-26.69,24.56-23.31,0-35.13-13.93-35.13-41.4,0-24.76,14.24-37.85,41.17-37.85A64.82,64.82,0,0,1,343,96.65l2,.91V80.37l-.83-.38a71.62,71.62,0,0,0-28-6c-17.52,0-31.5,4.47-41.54,13.29-11.18,9.81-16.85,24.58-16.85,43.9s5.65,34.15,16.78,43.86c11.54,10.07,26.12,12.19,36.33,12.19,10.5,0,22.12-3.61,30.13-9.91a37,37,0,0,0,14.5-30.13v-22.3H307.57Z"
    />
    <path
      fill="currentColor"
      d="M690.83,114.83c-4.87-4.84-12.22-10.36-22.2-10.36-20.71,0-34.75,16.62-34.81,40.42,0,25.82,13.64,41.85,34.94,41.85A26.51,26.51,0,0,0,690.83,175v10.21h16.72V106.28H690.83Zm0,43.6c-3.27,6.56-11.26,13.1-19.81,13.1-13.35,0-20.33-10.71-20.33-26.64,0-15.31,7.24-25.2,21.54-25.2,10.83,0,18.6,10.86,18.6,10.86Z"
    />
    <path
      fill="currentColor"
      d="M469.55,115c-4.87-4.79-12.64-10.52-23.2-10.52-18.49,0-33.82,15.07-33.82,39.63,0,24.34,13.81,38.87,37,38.87a31,31,0,0,0,20-7.91c0,17.65-4.53,25.43-22.2,25.43-8.42,0-17.16-2.27-23-4.17l-1.85-.61v15.46l.84.37a60.75,60.75,0,0,0,24.51,4.81c12.79,0,21.23-2.56,27.36-8.28,7.47-7,11.1-18.7,11.1-35.88V106.28H469.55Zm0,46.28c-2.8,3.09-9.08,6.78-17.8,6.78-13.57,0-22.33-9.46-22.33-24.1,0-15,6.5-24.26,20.54-24.26,10.39,0,19.59,9.24,19.59,9.24Z"
    />
    <path
      fill="currentColor"
      d="M768.6,104.47c-9.91,0-17.69,6-22.22,11v-9.22H729.66v78.95h16.72V130.55s8.51-9.81,19.2-9.81c11.35,0,16.64,6,16.64,18.83v45.66h16.72V137.3C798.94,116.13,788.33,104.47,768.6,104.47Z"
    />
  </svg>
))

GigplanetLogo.propTypes = {
  alt: PropTypes.string,
  inverted: PropTypes.bool,
}

GigplanetLogo.defaultProps = {
  alt: '',
  inverted: false,
}

export default GigplanetLogo
