import * as React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import Loader from '../Loader'
import { LoadingWrapper, TextWrapper, IconAction, StyledInput, StyledButton, Text } from './style'

const Button = React.forwardRef(
  ({ type, theme, href, wide, disabled, loading, to, size, uppercase, onClick, children }, ref) => {
    const loadingElement = (
      <LoadingWrapper isLoading={loading}>
        <Loader white={theme === 'primary'} />
      </LoadingWrapper>
    )
    const childrenElement = <TextWrapper>{children}</TextWrapper>
    // Let's disable this link if we are loading something.
    const handleClick = (e) => {
      if (loading) {
        e.preventDefault()
      } else if (onClick) {
        onClick(e)
      }
    }

    switch (theme) {
      case 'icon-action':
        return (
          <IconAction
            ref={ref || null}
            theme={theme}
            disabled={disabled || loading}
            to={to}
            href={href}
            type={type}
            onClick={handleClick}
            isWide={wide}
            isLoading={loading}
            isUppercase={uppercase}
          >
            {childrenElement}
            {loadingElement}
          </IconAction>
        )
      case 'input':
        return (
          <StyledInput
            ref={ref || null}
            disabled={disabled || loading}
            to={to}
            href={href}
            type={type}
            onClick={handleClick}
            isWide={wide}
            isLoading={loading}
            size={size}
            isUppercase={uppercase}
          >
            {childrenElement}
          </StyledInput>
        )

      case 'primary':
      case 'secondary':
      case 'secondaryNegative':
      case 'abort':
      case 'cancel':
      case 'transparent':
      case 'outline':
        return (
          <ThemeProvider theme={{ buttonType: theme }}>
            <StyledButton
              ref={ref || null}
              disabled={disabled || loading}
              to={to}
              href={href}
              type={type}
              onClick={handleClick}
              isWide={wide}
              isLoading={loading}
              size={size}
              isUppercase={uppercase}
            >
              <>
                {childrenElement}
                {loadingElement}
              </>
            </StyledButton>
          </ThemeProvider>
        )
      case 'text':
        return (
          <ThemeProvider theme={{ buttonType: theme }}>
            <Text
              ref={ref || null}
              disabled={disabled || loading}
              to={to}
              href={href}
              type={type}
              onClick={handleClick}
              isWide={wide}
              isUppercase={uppercase}
              size={size}
            >
              {childrenElement}
            </Text>
          </ThemeProvider>
        )

      default:
        return <div>Please choose theme</div>
    }
  },
)

Button.propTypes = {
  theme: PropTypes.oneOf([
    'icon-action',
    'primary',
    'secondary',
    'abort',
    'cancel',
    'text',
    'transparent',
    'outline',
    'input',
  ]).isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.shape({}),
    }),
  ]),
  href: PropTypes.string,
  wide: PropTypes.bool,
  loading: PropTypes.bool,
  uppercase: PropTypes.bool,
  size: PropTypes.oneOf(['normal', 'large', 'small']),
}

Button.defaultProps = {
  onClick: null,
  disabled: false,
  wide: false,
  loading: false,
  uppercase: false,
  type: '',
  to: '',
  href: '',
  size: 'normal',
}

export default Button
