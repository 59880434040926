import * as React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { getJSON } from '../../libs/data'

import withCurrentUser from '../../hocs/with-current-user'

const CHECK_TIMEOUT = 120000

const CheckUserStatus = React.memo(({ children, currentUser }) => {
  const [loggedIn, setLoggedIn] = React.useState({}.hasOwnProperty.call(currentUser, 'id'))
  let timeoutId = ''

  const checkStatus = () => {
    getJSON('/api/v1/user/status')
      .then(json => {
        if (window && window.location) {
          const url = `${window.location.pathname}${window.location.search}`
          const delimiter = url.indexOf('?') === -1 ? '?' : '&'
          if (loggedIn !== json.loggedIn) {
            if (json.loggedIn) {
              if (url.indexOf('autoLoggedIn') === -1) {
                window.location.replace(`${url}${delimiter}autoLoggedIn=true`)
              } else {
                window.location.replace(url)
              }
            } else if (url.indexOf('autoLoggedOut') === -1) {
              window.location.replace(`${url}${delimiter}autoLoggedOut=true`)
            } else {
              window.location.replace(url)
            }
            return
          }
        }
        setLoggedIn(json.loggedIn)
        timeoutId = setTimeout(checkStatus, CHECK_TIMEOUT)
      })
      .catch(() => {
        /* I don't like this
        if (window && window.location) {
          window.location.replace(window.location.pathname)
        }
        */
      })
  }

  React.useEffect(() => {
    setLoggedIn({}.hasOwnProperty.call(currentUser, 'id'))
    checkStatus()
    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  return children
})

CheckUserStatus.propTypes = {
  currentUser: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
}

CheckUserStatus.defaultProps = {
  currentUser: null,
}

export default compose(withCurrentUser)(CheckUserStatus)
